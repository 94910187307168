import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const {
  UPDATE_DEPOTS,
  DEPOT_STATUS_HISTORY,
  GET_ALL_DEMAND_RESPONSE_PROGRAMS,
  SEND_LEAP_CONNECT_URL,
  SHARED_CHARGING,
} = indexedEndPoints;

export const updateDepotDetails = async (payload) =>
  await apiProvider.put(UPDATE_DEPOTS, payload);

export const sendLeapConnectUrl = async (payload) =>
  await apiProvider.post(SEND_LEAP_CONNECT_URL, payload);

export const getDepotStatusHistory = async (
  depotId,
  activationHistory = false
) => {
  const url = `${DEPOT_STATUS_HISTORY.replace(
    "depotId",
    depotId
  )}?activationHistory=${activationHistory}`;
  return await apiProvider.getAll(url);
};

export const getDepotDRPrograms = async (depotId) =>
  await apiProvider.getAll(
    `${GET_ALL_DEMAND_RESPONSE_PROGRAMS}?depotId=${depotId}`
  );

export const getSharedChargingConfig = async (depotId) => {
  const url = `${SHARED_CHARGING.replace("depotId", depotId)}`;
  return await apiProvider.getAll(url);
};

export default { updateDepotDetails };
