import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Checkbox,
  Badge,
  Zoom,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEqual } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import Controls from "../../../../components/controls/Controls";
import useTable from "../../../../components/UseTable";
import { titleLabels } from "../../../../constants/TitleLabels";
import LaunchIcon from "@mui/icons-material/Launch";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import { formatDistance, format } from "date-fns";
import CommonFilterDrawer from "../../../../components/SideFilter";
import no_Data from "./../../../../assets/images/png/Vector.png";
import "./../../ChargerHealth.scss";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import sub from "date-fns/sub";
import add from "date-fns/add";
import { faultsExportCsv, updateFaultStatus } from "../../ChargerHealthService";
import { getData } from "../../../../utils/Storage";
import Loader from "../../../../components/Loader";
import { updateNotes, bulkUpdateNotes } from "../../ChargerHealthService";
import Popup from "../../../../components/Popup";
import ExportCSVAPI from "../../../../components/ExportCSVAPI";
import ActiveFilters from "../../../../components/all-filter-types/ActiveFilters";
import ErrorCodeDrawerDetails from "../../../data-catalog/errorCodes/ErrorCodeDrawerDetails";
import { routePaths } from "../../../../constants/RoutePaths";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    paddingRight: "10px",
    width: "30%",
    ["@media (max-width:1023px)"]: {
      width: "40%",
    },
  },
}));

const headings = [
  {
    key: "accountName",
    id: "accountName",
    label: "Account",
    styles: {
      position: "sticky",
      zIndex: 800,
      left: "74px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  { key: "depotName", id: "depotName", label: "Depot" },
  {
    key: "vendorManufacturer",
    id: "vendorManufacturer",
    label: "Manufacturer (OEM)",
  },
  { key: "vendorModel", id: "vendorModel", label: "Model (OEM)" },
  { key: "make", id: "make", label: "Sold as Manufacturer" },
  { key: "model", id: "model", label: "Sold as Model" },
  { key: "chargePortId", id: "chargePortId", label: "Charger Port ID" },
  {
    key: "connectivityStatus",
    id: "connectivityStatus",
    label: "Connectivity Status",
  },
  { key: "errorCode", id: "errorCode", label: "Fault Code" },
  { key: "vendorErrorCode", id: "vendorErrorCode", label: "Vendor Error Code" },
  { key: "faultNumber", id: "faultNumber", label: "Fault Number" },
  { key: "title", id: "title", label: "Fault Title" },
  {
    key: "severity",
    id: "severity",
    label: (
      <div className="rfidStatus">
        <p>Fault Impact</p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title={
            <ul>
              <li>
                <b>High:</b> A fault that prevents charging. If the issue has
                cleared, charging may become functional again
              </li>
              <li>
                <b>Medium:</b> A fault that negatively impacts, but does not
                prevent charging (e.g. charging power reduced due to low or high
                temparatures)
              </li>
              <li>
                <b>Low:</b> A fault that directly does not impact charging but
                informs about a change to the charger
              </li>
              <li>
                <b>Diagnostic:</b> A fault that is not displayed in the customer
                UI or fault alert messages regardless of user settings
              </li>
              <li>
                <b>Unknown:</b> A fault that is displayed to customers although
                severity level has not been confirmed
              </li>
            </ul>
          }
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
    csvLabel: "Fault Impact",
  },
  {
    key: "chargerStatusIndicator",
    id: "chargerStatusIndicator",
    label: "Expected Status Indicator",
  },
  { key: "createdAt", id: "createdAt", label: "Open duration", type: "date" },
  { key: "status", id: "status", label: "Status" },
];

const extraTableHeadings = [
  {
    key: "noOfOccurrence",
    id: "noOfOccurrence",
    label: "Number Of Occurrences",
    checked: false,
  },
  {
    key: "latestEventTimestamp",
    id: "latestEventTimestamp",
    label: "Most Recent Occurrence",
    checked: false,
    type: "date",
  },
  {
    key: "modifiedBy",
    id: "modifiedBy",
    label: (
      <div className="rfidStatus">
        <p>Closed by</p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title={
            <>
              <p>
                Closed by data represents the different users who have closed
                the alert(s).
              </p>
              <ul>
                <li>
                  <b>Null(-):</b> The alert has not closed yet, its still in
                  open state
                </li>

                <li>
                  <b>System:</b> System has auto closed the alert and its marked
                  as <q>Self Clearing</q>
                </li>
                <li>
                  <b>OPS Console User:</b> The alert has been closed by the
                  internal user and its marked with <q>Actual CDS ID</q> for the
                  internal user.
                </li>
                <li>
                  <b>FPC Dashboard User:</b> The alert has been closed by the
                  FPC user and its marked with the actual FFM user ID e-mail
                  address for the external user.
                </li>
              </ul>
            </>
          }
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
  },
  {
    key: "faultClosedTimestamp",
    id: "faultClosedTimestamp",
    label: "Closed at",
    type: "date",
  },
];

const headCells = (
  setSelected,
  recordsAfterPagingAndSorting,
  setRecordsAfterPagingAndSorting
) => {
  const hc = [
    {
      disableSorting: true,
      id: "some",
      label: (
        <Checkbox
          checked={
            recordsAfterPagingAndSorting.length > 0 &&
            recordsAfterPagingAndSorting?.every((row) => row.selected)
          }
          onChange={(e) => {
            const updatedRecords = recordsAfterPagingAndSorting?.map((row) => ({
              ...row,
              selected: e.target.checked,
            }));
            setSelected(e.target.checked ? updatedRecords : []);
            setRecordsAfterPagingAndSorting(updatedRecords);
          }}
        />
      ),
      styles: {
        position: "sticky",
        zIndex: 801,
        left: 0,
      },
    },
    ...headings,
    ...extraTableHeadings,
    { disableSorting: true, key: "action", id: "action", label: "Action" },
  ];
  return hc;
};

const extraCsvFields = [
  {
    key: "createdAt",
    label: "Created At (UTC)",
    checked: false,
  },
  {
    key: "createdAt",
    label: "Created At (Depot Local Time)",
    checked: false,
  },
  {
    key: "noOfOccurrence",
    label: "Number Of Occurrences",
    checked: false,
  },
  {
    key: "latestEventTimestamp",
    label: "Most Recent Occurrence (UTC)",
    checked: false,
  },
  {
    key: "latestEventTimestamp",
    label: "Most Recent Occurrence (Depot Local Time)",
    checked: false,
  },
  { key: "status", label: "Closed At (UTC)", checked: false },
  { key: "status", label: "Closed At (Depot Local Time)", checked: false },
  { key: "closedBy", label: "Closed By", checked: false },
];

const Rows = React.memo((props) => {
  const {
    item,
    toggleDrawerTCG,
    getTroubleShootData,
    handleSingleClick,
    handleStatusChange,
    componentPrivilege,
  } = props;
  const history = useHistory();

  const fetchOpenDurationTime = (fault) => {
    let openDuration = titleLabels.NOT_AVAILABLE;
    if (fault.status === "Closed" && fault.createdAt !== undefined) {
      openDuration = (
        <>
          <p className="openDurationDist">
            Closed after{" "}
            {formatDistance(
              new Date(new Date(item.createdAt)),
              new Date(
                new Date(
                  item.faultClosedTimestampUtc || item.latestEventTimestamp
                )
              )
            ) || titleLabels.NOT_AVAILABLE}
          </p>
        </>
      );
    } else if (fault.createdAt !== undefined) {
      openDuration = (
        <>
          <p className="openDurationDist">
            {formatDistance(
              new Date(new Date(`${item.createdAt}`)),
              new Date()
            ) || titleLabels.NOT_AVAILABLE}
          </p>
        </>
      );
    }
    return openDuration;
  };

  const convertToUTCDateTime = (date) =>
    new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000
    );

  return (
    <>
      <TableRow hover={true} className="opentable" id="openFaultsTable">
        <TableCell align="center" className="faultsCheckboxCell">
          <Checkbox
            onClick={(e) => handleSingleClick(e, item)}
            checked={item?.selected === true}
          />
        </TableCell>
        <TableCell align="left" className="faultsAccountCell">
          {item.accountName || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.depotName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.vendorManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.vendorModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.make || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.model || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <Tooltip title={item.chargePortId || titleLabels.NOT_AVAILABLE}>
            <span>
              <Controls.ActionButton
                className="noTextTransform"
                color="primary"
                onClick={() =>
                  history.push(
                    `${routePaths.CHARGERFIRMWAREDETAILS}?chargerId=${item.chargePortId}`
                  )
                }
              >
                {item.chargePortId}
              </Controls.ActionButton>
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <div>
            <Chip
              label={item.connectivityStatus}
              style={{
                color:
                  item.connectivityStatus === "Connected"
                    ? "#00765E"
                    : "#D01300",
              }}
            />
            {item.connectivityStatus === "Not Connected" &&
              item.lastCommunication && (
                <p className="lastCommunicationTime">
                  Since{" "}
                  {format(
                    convertToUTCDateTime(item.lastCommunication),
                    "dd/MM/yyyy HH:mm"
                  )}{" "}
                  UTC
                </p>
              )}
          </div>
        </TableCell>
        <TableCell>
          <Tooltip title={item.errorCode || titleLabels.NOT_AVAILABLE}>
            <p className="table_col_ellipsis">
              {item.errorCode || titleLabels.NOT_AVAILABLE}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell>
          {item.vendorErrorCode || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.faultNumber || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <Tooltip title={item.title || titleLabels.NOT_AVAILABLE}>
            <p className="table_col_ellipsis">
              {item.title || titleLabels.NOT_AVAILABLE}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell>{item.severity || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.chargerStatusIndicator || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{fetchOpenDurationTime(item)}</TableCell>
        <TableCell align="center">
          {item.status ? (
            <>
              {componentPrivilege?.includes("Update Fault Status") ? (
                <FormControl
                  className="faultStatusCell"
                  variant="outlined"
                  size="small"
                >
                  <Select
                    value={item.status ? item.status : null}
                    onChange={(e) => handleStatusChange(e, item)}
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Closed">Close</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                item.status
              )}
            </>
          ) : (
            "No Status"
          )}
        </TableCell>
        <TableCell>
          {item.noOfOccurrence
            ? item.noOfOccurrence
            : titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {(
            <>
              {" "}
              {
                new Date(format(new Date(item.latestEventTimestamp), "PPpp"))
                  .toString()
                  .split("GMT")[0]
              }
            </>
          ) || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {item.status === "Closed" ? item.modifiedBy || "N/A" : "-"}
        </TableCell>
        <TableCell>
          {item.status === "Closed"
            ? new Date(
                format(
                  new Date(
                    item.faultClosedTimestamp &&
                    item.faultClosedTimestamp !== titleLabels.NOT_AVAILABLE
                      ? item.faultClosedTimestamp
                      : item.latestEventTimestamp
                  ),
                  "PPpp"
                )
              )
                .toString()
                .split("GMT")[0]
            : titleLabels.NOT_AVAILABLE}
        </TableCell>

        <TableCell className="dataDogCell">
          <div className="flexDisplay">
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://app.datadoghq.com/logs?query=%40siteId%3A${
                    item.depotId
                  }%20%40chargerId%3A${
                    item.chargePortId
                  }&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&from_ts=${new Date(
                    sub(
                      new Date(
                        item.faultClosedTimestamp || item.latestEventTimestamp
                      ),
                      {
                        minutes: 2,
                      }
                    ).toISOString()
                  ).getTime()}&to_ts=${new Date(
                    add(
                      new Date(
                        item.faultClosedTimestamp || item.latestEventTimestamp
                      ),
                      {
                        minutes: 2,
                      }
                    ).toISOString()
                  ).getTime()}&live=false`,
                  "_blank"
                );
              }}
            >
              <Tooltip title="Link to DataDog">
                <LaunchIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
            <Controls.ActionButton
              color="primary"
              onClick={async () => {
                await getTroubleShootData(item);
                toggleDrawerTCG(true, "Troubleshooting Guide", {});
              }}
            >
              <Tooltip title="Troubleshooting guide">
                <ViewAgendaOutlinedIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
});

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    setOpenPopup,
    isFiltered,
    selected,
    handleMultiStatusChange,
    faults,
    data,
    selectAllFaults,
    clearSelectAllFaults,
    recordsAfterPagingAndSorting,
    componentPrivilege,
    activeFilters,
    clearAllFilters,
  }) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleUserSettings = () => {
      setAnchorEl(null);
      history.push("/userAlertSettings");
    };
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        {selected.length > 0 && selected.length === faults.length && (
          <p className="selected_Check_box_string">
            All {selected.length} faults are selected
          </p>
        )}
        {selected.length > 0 &&
          selected.length === recordsAfterPagingAndSorting.length &&
          selected.length !== faults.length && (
            <p className="selected_Check_box_string">
              All {selected.length} faults on this <br /> page are selected
            </p>
          )}
        {selected.length > 1 &&
          selected.length !== faults.length &&
          selected.length !== recordsAfterPagingAndSorting.length && (
            <p className="selected_Check_box_string">
              <span>{selected.length}</span> faults are selected
            </p>
          )}
        {faults.length !== selected.length && selected.length > 0 && (
          <Controls.ActionButton onClick={selectAllFaults}>
            <p className="selected_Check_box_string" id="colorCodeString">
              Select all {faults.length} faults
            </p>
          </Controls.ActionButton>
        )}
        {faults.length === selected.length && selected.length > 0 && (
          <Controls.ActionButton onClick={clearSelectAllFaults}>
            <p className="selected_Check_box_string" id="colorCodeString">
              Clear selection
            </p>
          </Controls.ActionButton>
        )}
        {componentPrivilege?.includes("Update Fault Status") && (
          <>
            {selected.length > 1 && (
              <FormControl
                variant="outlined"
                size="small"
                className="multiStatusSelect"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  label="Status"
                  onChange={(e) => handleMultiStatusChange(e)}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed">Close</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        )}
        <Grid sm item />
        <Controls.Button
          text="SUBSCRIBE"
          variant="outlined"
          disabled={data.length === 0}
          id="offlinePortsExportCSV"
          onClick={handleUserSettings}
        />
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          disabled={data.length === 0}
          id="offlinePortsExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
        <ActiveFilters filters={activeFilters} clearAll={clearAllFilters} />
        <Badge color="primary" variant="dot" invisible={!isFiltered}>
          <Controls.Button
            text="Filter"
            variant="outlined"
            startIcon={
              <TuneIcon style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }} />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    callAPI,
    toggleDrawerTCG,
    getTroubleShootData,
    handleSingleClick,
    handleStatusChange,
    componentPrivilege,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            callAPI={callAPI}
            toggleDrawerTCG={toggleDrawerTCG}
            getTroubleShootData={getTroubleShootData}
            handleSingleClick={handleSingleClick}
            handleStatusChange={handleStatusChange}
            componentPrivilege={componentPrivilege}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const FaultsTable = (props) => {
  const {
    toggleDrawer,
    faults,
    setFaults,
    isTroubleShootDataLoading,
    troubleShootData,
    getTroubleShootData,
    selectedFilter,
    isFiltered,
    getOpenFaultsWithDepots,
    setToast,
    setToastSecond,
    activeFilters,
    clearAllFilters,
  } = props;
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const getHeaders = () => {
    let heads = headings?.map((row) => ({
      ...row,
      label: row.csvLabel || row.label,
      checked: false,
    }));
    heads.push(...extraCsvFields);
    return heads;
  };
  const [headers, setHeaders] = useState(getHeaders());
  const componentPrivilege = useSelector(
    (state) => state?.user?.componentPrivilege?.chargerHealth
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);

  const handleSingleClick = (e, item) => {
    const updatedRecords = [...recordsAfterPagingAndSorting];
    const selectedIndex = recordsAfterPagingAndSorting.indexOf(item);
    updatedRecords[selectedIndex] = {
      ...updatedRecords[selectedIndex],
      selected: !updatedRecords[selectedIndex]?.selected,
    };
    setRecordsAfterPagingAndSorting(updatedRecords);
    setSelected(
      e.target.checked
        ? updatedRecords?.filter((row) => row.selected)
        : selected?.filter((row) => row.faultId !== item.faultId)
    );
  };

  const handleStatusChange = (event, item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Change status",
      subTitle: "Are you sure you want to save the changes of this fault?",
      type: "secondary",
      onConfirm: () => {
        singleStatusChange(item, event);
      },
    });
  };

  const handleMultiStatusChange = (event) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Change status",
      subTitle:
        "Are you sure you want to save the changes of the selected faults?",
      type: "secondary",
      onConfirm: () => {
        multiStatusChange(event);
      },
    });
  };

  const multiStatusChange = async (event) => {
    setIsLoading(true);
    const selectedDepotIds = [
      ...new Set(selected.map((value) => value.depotId)),
    ];
    const payload = {
      status: event.target.value,
      user: `${getData("user_first_name")} ${getData("user_last_name")}`,
      depotFaultsDetails: [],
    };
    selectedDepotIds.map((single) => {
      const tempObj = {
        depotId: single,
        depotApiUrl: selected.find((o) => o.depotId === single).apiUrl,
        ids: selected
          .filter((a) => a.depotId === single)
          .map((value) => value.faultId),
      };
      payload.depotFaultsDetails.push(tempObj);
    });

    const res = await updateFaultStatus(payload);
    if (res?.statusCode >= 200 && res?.statusCode <= 299) {
      let fulfilled = [];
      let rejected = 0;
      res?.data?.result?.map((single) => {
        if (single?.status_code == 204) {
          fulfilled.push(
            ...payload.depotFaultsDetails.find(
              (a) => a.depotId == single.depotId
            ).ids
          );
        } else {
          rejected =
            rejected +
            payload.depotFaultsDetails.find(
              (a) => a.depotId == single.reason.depotId
            ).ids.length;
        }
      });
      if (fulfilled.length !== 0) {
        setToast({
          isOpen: true,
          message: `${fulfilled.length} Fault Status Changed Successfully `,
          type: "success",
        });
        const notesPayload = fulfilled?.map((row) => ({
          faultId: row,
          status: event.target.value,
        }));
      }
      if (rejected !== 0) {
        setToastSecond({
          isOpen: true,
          message: `${rejected} Fault Status did not Changed `,
          type: "error",
        });
      }
      getOpenFaultsWithDepots(selectedFilter);
    }
    setIsLoading(false);
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const singleStatusChange = async (item, event) => {
    setIsLoading(true);
    const payload = {
      status: event.target.value,
      user: `${getData("user_first_name")} ${getData("user_last_name")}`,
      depotFaultsDetails: [
        {
          depotId: item.depotId,
          depotApiUrl: item.apiUrl,
          ids: [item.faultId],
        },
      ],
    };
    const res = await updateFaultStatus(payload);
    if (res?.statusCode >= 200 && res?.statusCode <= 299) {
      if (res?.data?.result[0]?.status_code == 204) {
        setToast({
          isOpen: true,
          message: "Fault Status Changed Successfully ",
          type: "success",
        });

        getOpenFaultsWithDepots(selectedFilter);
      } else {
        setToast({
          isOpen: true,
          message: "Faults Status did not Changed",
          type: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        message: "Sorry SomeThing Went Wrong",
        type: "error",
      });
    }
    setIsLoading(false);
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const selectAllFaults = () => {
    const updatedFaults = faults?.map((row) => ({ ...row, selected: true }));
    setFaults(updatedFaults);
    setSelected(updatedFaults);
  };

  const clearSelectAllFaults = () => {
    setFaults(faults?.map((row) => ({ ...row, selected: false })));
    setSelected([]);
  };

  const [tableHeadCells, setTableheadCells] = React.useState(
    headCells(
      setSelected,
      recordsAfterPagingAndSorting,
      setRecordsAfterPagingAndSorting
    )
  );
  const result = useTable(faults, tableHeadCells, filterFn, true);

  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);

  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);

  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  React.useEffect(() => {
    if (selected.length !== faults.length) {
      setRecordsAfterPagingAndSorting(
        result.recordsAfterPagingAndSorting?.map((row) => ({
          ...row,
          selected: false,
        }))
      );
      setSelected([]);
    } else {
      setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
    }
  }, [result.recordsAfterPagingAndSorting]);

  React.useEffect(() => {
    setTableheadCells(
      headCells(
        setSelected,
        recordsAfterPagingAndSorting,
        setRecordsAfterPagingAndSorting
      )
    );
  }, [recordsAfterPagingAndSorting]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target?.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };
  const [DrawerTCG, setDrawerTCG] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const toggleDrawerTCG = (openClose, title, item) => {
    setDrawerTCG({ isOpen: openClose, title: title, data: item });
  };

  const exportCsvApiCall = async (data) => {
    let payload = {};
    if (data === "all") {
      payload["requiredColumns"] = headers.map((col) => col.label);
    } else
      payload["requiredColumns"] = headers
        .filter((col) => col.checked)
        .map((col) => col.label);

    if (Object.keys(selectedFilter).length !== 0) {
      const filters = {};
      for (let [key, value] of Object.entries(selectedFilter))
        if (value?.length) filters[key] = value;
      Object.assign(payload, filters);
    }
    setIsLoading(true);
    setOpenPopup({
      isOpen: false,
      title: "Export CSV",
      child: "exportCSV",
    });
    try {
      const res = await faultsExportCsv(payload);
      if (res !== 0) {
        setToast({
          isOpen: true,
          message: "File Downloaded Successfully",
          type: "success",
        });
      } else {
        setToast({
          isOpen: true,
          message: "Sorry, File did not download",
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
      setToast({
        isOpen: true,
        message: "Sorry, File did not download",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className={classes.searchInput}
        toggleDrawer={toggleDrawer}
        setOpenPopup={setOpenPopup}
        isFiltered={isFiltered}
        selected={selected}
        handleMultiStatusChange={handleMultiStatusChange}
        faults={faults}
        data={result?.searchData}
        selectAllFaults={selectAllFaults}
        clearSelectAllFaults={clearSelectAllFaults}
        recordsAfterPagingAndSorting={recordsAfterPagingAndSorting}
        componentPrivilege={componentPrivilege}
        activeFilters={activeFilters}
        clearAllFilters={clearAllFilters}
      />
      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised
            data={recordsAfterPagingAndSorting}
            toggleDrawerTCG={toggleDrawerTCG}
            getTroubleShootData={getTroubleShootData}
            handleSingleClick={handleSingleClick}
            handleStatusChange={handleStatusChange}
            componentPrivilege={componentPrivilege}
          />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}
      {TablePagination}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Loader isLoading={isLoading || isTroubleShootDataLoading} />
      <CommonFilterDrawer DrawerOC={DrawerTCG} toggleDrawer={toggleDrawerTCG}>
        {troubleShootData ? (
          <ErrorCodeDrawerDetails drawerObj={troubleShootData} />
        ) : (
          <div className="troubleShoot_sec_bg">
            <p className="no_data">
              There are no possible resolutions provided by the manufacturer.
            </p>
          </div>
        )}
      </CommonFilterDrawer>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "exportCSV" && (
          <ExportCSVAPI
            setOpenPopup={setOpenPopup}
            headers={headers}
            setHeaders={setHeaders}
            exportCsvApiCall={exportCsvApiCall}
          />
        )}
      </Popup>
    </>
  );
};

export default FaultsTable;
