import {
  Chip,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Zoom,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Controls from "../../../../components/controls/Controls";
import "../../../billing/MoreInfo.scss";
import ToastMessage from "../../../../components/ToastMessage";
import { chargingModeEnum, firmwareStatusEnum } from "../../CHDEnum";
import { titleLabels } from "../../../../constants/TitleLabels";
import chargerImage from "../../../../assets/images/png/petrol.png";
import busImage from "../../../../assets/images/svg/bus.svg";
import publicCarImage from "../../../../assets/images/svg/public-car.svg";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../../services/api/utilities/provider";
import "../../ChargerHealth.scss";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import ScienceIcon from "@mui/icons-material/Science";
import AddEditChargerPort from "./AddEditChargerPort";
import NewCommonFilterDrawer from "../../../../components/NewSideFIlterDrawer";
import AlertDialog from "../../../../components/NewAlertDialog";
import DeleteConfirmDialog from "../../../../components/NewDeleteConfirmDialog";
import { PrivilegedComponent } from "../../../../utils/PrivilegedComponent";
import {
  getChargerSimCardInfo,
  getOCPPSettingsByCharger,
} from "../chargerFirmwareService";
const colors = { Enabled: "#00765E", Capable: "#22539B", Incapable: "#18191E" };
const backgrounds = {
  Enabled: "#E5F1EF",
  Capable: "#E7EBF3",
  Incapable: "#F0F1F8",
};

const rfidColors = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#916D1F";
  } else {
    return colors[rfidObj?.status];
  }
};

const rfidBGColor = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#FEF8EB";
  } else {
    return backgrounds[rfidObj?.status];
  }
};

const ChargerdetailsSideBar = (props) => {
  const data = props.DraweChargerDetails.data;
  const { setPortCount } = props;
  const allAccounts = props.allAccounts;
  const { CHARGER_EXTRA_DETIALS, CHARGER_PROFILE, GET_DRIVERS } =
    indexedEndPoints;
  const [details, setDetails] = useState([]);
  const [lists, setLists] = useState([]);
  const [driver, setDriver] = useState();
  const [depotDetails, setDepotDetails] = useState([]);
  const [simDetails, setSimDetails] = useState("");
  const [isExpanded, setExpanded] = useState({
    chargers: true,
    ports: false,
    rfidConfigs: true,
  });

  const p_tag_hyper = {
    cursor: "pointer",
    color: "#0051A2",
  };

  const getRfidRefConf = () => {
    const rfidMode =
      data?.rfidDetails?.rfidConfigMode === "freevend"
        ? "freevendValue"
        : "authValue";
    const rfidConf = data?.rfidConfig?.map((item) => ({
      key: item.key,
      refConf: item[rfidMode]?.toString(),
      isMatch: true,
    }));
    return rfidConf;
  };

  const [rfidConf, setRfidConf] = useState(getRfidRefConf());

  const constructChargePortDetails = () => {
    let list = [];
    if (data.chargeports) {
      data.chargeports.map((element) => {
        if (element.portId) {
          list.push({
            key: element.portId,
            label: element.portName,
            items: [
              {
                key: element.typeConnector,
                label: "Connector type",
                value: element.typeConnector,
              },
              {
                key: element.maxAmps,
                label: "Max Amps (A)",
                value: element.maxAmps,
              },
              {
                key: element.minimumChargingRate,
                label: "Min charging rate(kW)",
                value: element.minimumChargingRate,
              },
              {
                key: element.maxCapacity,
                label: "Max charging rate(kW)",
                value: element.maxCapacity,
              },
            ],
          });
        }
        setLists(list);
      });
    }
  };

  React.useEffect(constructChargePortDetails, []);

  React.useEffect(() => {
    if (allAccounts?.length > 0)
      setDepotDetails(
        allAccounts[0]?.children
          .find((acc) => acc.companyId === data.accountId)
          ?.depotsList.find((depot) => depot.depotId === data.depotId)
      );
  }, [allAccounts]);

  useEffect(() => {
    const fetchDrivers = async () => {
      const response = await apiProvider.getAll(
        `${GET_DRIVERS}${data.depotId}`
      );
      const driverObj = response?.data?.find(
        (item) => item?._id === data?.driver
      );
      if (driverObj)
        setDriver(
          driverObj?.firstName?.trim() + " " + driverObj?.lastName?.trim()
        );
    };
    fetchDrivers();
  }, []);

  React.useEffect(() => {
    if (data.depotId && data.chargerId) {
      getOtherChargerDetails(data.depotId, data.chargerId);
    }
  }, [data.depotId, data.chargerId]);

  const getOtherChargerDetails = async (depotId, chargerId) => {
    const response = await apiProvider.getAll(
      `${CHARGER_EXTRA_DETIALS}/${depotId}/${chargerId}/details`
    );
    setDetails(response.data);
  };

  const getOCPPConfigsByCharger = async (depotId = "", chargerId = "") => {
    props.setLoading(true);
    const res = await getOCPPSettingsByCharger(depotId, chargerId);
    props.setLoading(false);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      const values =
        res?.data?.[0]?.lastKnownOcppSettings?.configuration?.values;
      const conf = rfidConf?.map((row) => {
        const matched = values?.find((item) => item.key === row.key);
        const localConf = matched?.value || "";
        const timestamp = matched?.timestamp || "";
        let obj = {
          ...row,
          localConf,
          timestamp,
          isMatch: matched ? localConf === row.refConf : true,
        };
        return obj;
      });
      setRfidConf(conf);
    }
  };

  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const toggleDetailDrawer = (openClose, title, child, item) => {
    setDrawerOC({ isOpen: openClose, title: title, child: child, data: item });
  };

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [anchorEl, setAnchorEl] = React.useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    const updatedAnchorElArr = [...anchorEl];
    updatedAnchorElArr[index] = event.currentTarget;
    setAnchorEl(updatedAnchorElArr);
  };
  const handleClose = (index) => {
    const updatedAnchorElArr = [...anchorEl];
    updatedAnchorElArr[index] = null;
    setAnchorEl(updatedAnchorElArr);
  };
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [alertConfirmDialog, setAlertConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const deletePort = useCallback(async (item, data) => {
    props.setLoading(true);
    const response = await apiProvider.del(
      `${CHARGER_PROFILE}/${data.depotId}/${data.objectId}/chargeports/${item.connectorId}`
    );

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      data.chargePortsCount = data.chargePortsCount - 1;
      setToast({
        isOpen: true,
        message: "Port Deleted",
        type: "success",
      });
      getOtherChargerDetails(data.depotId, data.chargerId);
    } else {
      setToast({
        isOpen: true,
        message: "Port deleting failed",
        type: "error",
      });
    }
    setDeleteConfirmDialog({ ...deleteConfirmDialog, isOpen: false });
    props.setLoading(false);
  }, []);

  const getSessiondetails = async () => {
    const portObjectIds = details?.portsDetails?.map(
      (item) => item?.portObjectId
    );
    const portsString = "portIds=" + portObjectIds?.join(",");
    const response = await apiProvider.getAll(
      `${CHARGER_PROFILE}/${data?.depotId}/session-details/?${portsString}`
    );
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      const updatedPortsDetails = details?.portsDetails?.map((port) => {
        const match = response?.data?.find(
          (item) => item._id === port.portObjectId
        );
        if (match) {
          port.kWhDispensed = match.kWhDispensed;
          port.totalSessions = match.totalSession;
        } else {
          port.kWhDispensed = 0;
          port.totalSessions = 0;
        }
        return port;
      });
      setDetails({
        ...details,
        portsDetails: updatedPortsDetails,
        extraDetailsFetch: true,
      });
    }
  };

  useEffect(
    () => setPortCount({ [data.chargerId]: data.chargePortsCount }),
    [data.chargePortsCount]
  );

  const getSimDetails = async (chargerId) => {
    props.setLoading(true);
    const res = await getChargerSimCardInfo(chargerId);
    props.setLoading(false);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setSimDetails(res?.data);
    }
  };

  return (
    <>
      <div className="chargeDetailsSideDrawerContainer">
        <div className="chargeDetailsSideDrawer">
          <div className="charge_name_section">
            <div className="charge_name">
              <div className="charge_img">
                <img src={chargerImage} alt="Depot" className="depot" />
              </div>
              <div className="charge_name_and_number">
                <p className="name">
                  {data.chargerName || titleLabels.NOT_AVAILABLE}
                </p>
                <p className="number">
                  {data.serialNumber || titleLabels.NOT_AVAILABLE}
                </p>
              </div>
            </div>

            <div className="charge_connect">
              <div className="charger_type">
                <p>{data.networkType || titleLabels.NOT_AVAILABLE}</p>
              </div>
              <div
                className="charger_status"
                id={
                  data.connectivityStatus
                    ? data.connectivityStatus == "Not Connected"
                      ? "RED"
                      : "GREEN"
                    : "NA"
                }
              >
                <p> {data.connectivityStatus || titleLabels.NOT_AVAILABLE}</p>
              </div>
            </div>
          </div>
          <div className="charger_details_section">
            <div
              className="charger_heading chargerSidebarContainer"
              onClick={() =>
                setExpanded({ ...isExpanded, chargers: !isExpanded.chargers })
              }
            >
              <p>CHARGER DETAILS</p>
              {!isExpanded.chargers ? (
                <ExpandMoreIcon className="accordion_arrows" />
              ) : (
                <ExpandLessIcon className="accordion_arrows" />
              )}
            </div>
            {isExpanded.chargers && (
              <div className="all_details">
                <div className="single_detail">
                  <p className="c_title">Charger ID:</p>
                  <p className="description">
                    {data.chargerId || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>

                <div className="single_detail">
                  <p className="c_title">Charger Object Id:</p>
                  <p className="description">
                    {data.objectId || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>

                <div className="single_detail">
                  <p className="c_title">Account:</p>
                  <p className="description">
                    {data.accountName || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Depot:</p>
                  <p className="description">
                    {" "}
                    {data.depotName || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Site type:</p>
                  <p className="description">
                    {data.siteType || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Manufacturer (OEM):</p>
                  <p className="description">
                    {data.vendorManufacturer || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Model (OEM):</p>
                  <p className="description">
                    {data.vendorModel || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Sold as manufacturer:</p>
                  <p className="description">
                    {data.chargerManufacturer || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Sold as model:</p>
                  <p className="description">
                    {data.chargerModel || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charger driver:</p>
                  <p className="description">
                    {driver || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charger Latitude:</p>
                  <p className="description">
                    {data.latitude || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charger Longitude:</p>
                  <p className="description">
                    {data.longitude || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">OCPP System:</p>
                  <p className="description">
                    {data.ocppSystem || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charger commissioned:</p>
                  <p className="description">
                    <Chip
                      label={data.firstCommunication ? "Yes" : "No"}
                      style={{
                        color: data.firstCommunication ? "#00765E" : "#D01300",
                        background: data.firstCommunication
                          ? "#E5F1EF"
                          : "#FAE7E5",
                      }}
                    />
                  </p>
                </div>
                {data.isHomeChargingDepot && (
                  <div className="single_detail">
                    <p className="c_title">Mina Enrolled:</p>
                    <p className="description">
                      <Chip
                        label={data.isMinaEnrolled ? "Yes" : "No"}
                        style={{
                          color: data.isMinaEnrolled ? "#00765E" : "#D01300",
                          background: data.isMinaEnrolled
                            ? "#E5F1EF"
                            : "#FAE7E5",
                        }}
                      />
                    </p>
                  </div>
                )}
                {depotDetails?.isAdvenirEnrolledDepot && (
                  <div className="single_detail">
                    <p className="c_title">Advenir Enrolled:</p>
                    <p className="description">
                      <Chip
                        label={data.isAdvenirEnrolled ? "Yes" : "No"}
                        style={{
                          color: data.isAdvenirEnrolled ? "#00765E" : "#D01300",
                          background: data.isAdvenirEnrolled
                            ? "#E5F1EF"
                            : "#FAE7E5",
                        }}
                      />
                    </p>
                  </div>
                )}
                <div className="single_detail">
                  <p className="c_title">
                    <div className="rfidStatus">
                      <p>RFID status:</p>
                      <Tooltip
                        arrow
                        TransitionComponent={Zoom}
                        title=<ul>
                          <li>
                            <b>Enabled:</b> This charger currently requires the
                            tap of an authorized RFID card to start dispensing
                            energy in a charge session.
                          </li>
                          <li>
                            <b>Capable:</b> This charger does have a RFID
                            reader, and will be RFID-enabled when the RFID
                            controls are enabled for the profile.
                          </li>
                          <li>
                            <b>Incapable:</b> This charger does not have a RFID
                            reader, and will not be RFID-enabled, even if the
                            profile has RFID access controls enabled.
                          </li>
                        </ul>
                      >
                        <InfoIcon
                          fontSize="small"
                          className="rfidStatusInfoIcon"
                        />
                      </Tooltip>
                    </div>
                  </p>
                  <p className="description">
                    <Chip
                      avatar={
                        data?.rfidDetails?.rfidConfigMode &&
                        data?.rfidDetails?.isConfigMatched == false && (
                          <WarningIcon
                            fontSize="small"
                            style={{ color: "#916D1F" }}
                          />
                        )
                      }
                      label={data?.rfidDetails?.status}
                      style={{
                        color: rfidColors(data?.rfidDetails),
                        background: rfidBGColor(data?.rfidDetails),
                      }}
                    />
                    {data?.rfidDetails?.rfidConfigMode &&
                      data?.rfidDetails?.isConfigMatched == false && (
                        <span className="RFID_color_sub_title">
                          {data?.rfidDetails?.rfidConfigMode} config needed
                        </span>
                      )}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">SIM card details:</p>
                  <p className="description">
                    {simDetails !== "" ? (
                      typeof simDetails === "string" ? (
                        simDetails
                      ) : (
                        <div className="chargerSimDetails">
                          <Chip
                            label={`Provider: ${simDetails.simProvider}`}
                            style={{
                              color: "#00765E",
                              background: "#E5F1EF",
                            }}
                          />
                          <Chip
                            label={`Source: ${simDetails.simProviderSource}`}
                            style={{
                              color: "#00765E",
                              background: "#E5F1EF",
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <Button
                        className="noTextTransform"
                        onClick={() => getSimDetails(data?.chargerId)}
                      >
                        Get SIM card details
                      </Button>
                    )}
                  </p>
                </div>
                <Divider variant="fullWidth" className="charger_divider" />
                <div className="single_detail">
                  <p className="c_title">Charging mode:</p>
                  <p className="description">
                    {chargingModeEnum[data.chargingMode] ||
                      titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Power type:</p>
                  <p className="description">
                    {data.powerType || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Min charging rate(kW):</p>
                  <p className="description">
                    {data.minChargingRate >= 0
                      ? data.minChargingRate
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Max charging rate(kW):</p>
                  <p className="description">
                    {data.maxChargingRate >= 0
                      ? data.maxChargingRate
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">
                    Charger model min charging rate(kW):
                  </p>
                  <p className="description">
                    {data.modelMinChargingRate >= 0
                      ? data.modelMinChargingRate
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">
                    Charger model max charging rate(kW):
                  </p>
                  <p className="description">
                    {data.modelMaxChargingRate >= 0
                      ? data.modelMaxChargingRate
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charger model max amps(A):</p>
                  <p className="description">
                    {data.modelMaxAmps >= 0
                      ? data.modelMaxAmps
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Charging rate unit</p>
                  <p className="description">
                    {data.chargingRateUnit
                      ? data.chargingRateUnit
                      : titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Demand response:</p>
                  <p className="description">
                    <Chip
                      label={data.isChargerDemandResponseEnabled ? "Yes" : "No"}
                      style={{
                        color: data.isChargerDemandResponseEnabled
                          ? "#00765E"
                          : "#D01300",
                        background: data.isChargerDemandResponseEnabled
                          ? "#E5F1EF"
                          : "#FAE7E5",
                      }}
                    />
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Enrolled programs:</p>
                  <p className="description">
                    {data?.energyServicePrograms?.length
                      ? data?.energyServicePrograms?.map((program) => (
                          <Chip
                            label={program}
                            style={{
                              color: "#18191E",
                              background: "#F0F1F8",
                            }}
                          />
                        ))
                      : "-"}
                  </p>
                </div>
                {data?.energyServicePrograms?.includes("CPS") && (
                  <div className="single_detail">
                    <p className="c_title">Meter ID:</p>
                    <p className="description">
                      {data?.cpsProgramMeterId || titleLabels.NOT_AVAILABLE}
                    </p>
                  </div>
                )}
                {data?.derateConfig?.isChargerDerated && (
                  <div className="chargerDerateBanner">
                    <InfoIcon fontSize="small" className="leftInfoIcon" />
                    <p>
                      Charger and its associated ports were derated to{" "}
                      {data?.derateConfig?.deratedMaxAmps} A{" "}
                      <span className="timestamp">
                        on{" "}
                        {data?.derateConfig?.timestamp?.replace("GMT", "UTC")}
                      </span>
                    </p>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title={
                        "Indicates a hardware derate, which is set by sending a changeConfiguration command to the charger."
                      }
                    >
                      <InfoIcon fontSize="small" className="rightInfoIcon" />
                    </Tooltip>
                  </div>
                )}
                {data?.maxAmps < data?.modelMaxAmps && (
                  <div className="chargerDerateBanner">
                    <InfoIcon fontSize="small" className="leftInfoIcon" />
                    <p>Charger and its ports limited to {data?.maxAmps} A</p>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title={
                        "Indicates a software limit, which is set via Ops Console or Depot Charging software. The software limit is used by the charging algorithm and will not apply if the charger is offline."
                      }
                    >
                      <InfoIcon fontSize="small" className="rightInfoIcon" />
                    </Tooltip>
                  </div>
                )}
                <Divider variant="fullWidth" className="charger_divider" />
                {/* <div className="single_detail">
                <p className="c_title">Warranty:</p>
                <p className="description">
                  {data.warranty || titleLabels.NOT_AVAILABLE}
                </p>
              </div> */}
                <div className="single_detail">
                  <p className="c_title">Firmware version:</p>
                  <p className="description centerAligned">
                    {data.isTestVersion && (
                      <ScienceIcon
                        fontSize="small"
                        className="testVersionIcon"
                      />
                    )}{" "}
                    {data.firmwareVersion || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Firmware status:</p>
                  <p className="description">
                    {firmwareStatusEnum[data.firmwareStatus] ||
                      titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Configuration URL:</p>
                  <p className="description">
                    <Controls.NewCopyToClipboard
                      name={data.configurationUrl}
                      setToast={setToast}
                    />
                  </p>
                </div>
                <Divider variant="fullWidth" className="charger_divider" />
                <div className="single_detail">
                  <p className="c_title">ICCID:</p>
                  <p className="description">
                    {details?.simCardId || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Lifetime energy dispensed:</p>

                  {details?.portsDetails?.length ? (
                    <p
                      className="description"
                      style={!details?.extraDetailsFetch ? p_tag_hyper : null}
                      onClick={() => {
                        getSessiondetails();
                      }}
                    >
                      {details?.extraDetailsFetch
                        ? `${
                            details.portsDetails.reduce(
                              (acc, port) => acc + port.kWhDispensed,
                              0
                            )
                              ? details.portsDetails
                                  .reduce(
                                    (acc, port) => acc + port.kWhDispensed,
                                    0
                                  )
                                  .toFixed(2)
                              : 0
                          } kWh`
                        : "Click here to load"}
                    </p>
                  ) : (
                    <p className="description">No Ports</p>
                  )}
                </div>

                <div className="single_detail">
                  <p className="c_title">Total Charge Sessions:</p>

                  {details?.portsDetails?.length ? (
                    <p
                      className="description"
                      style={!details?.extraDetailsFetch ? p_tag_hyper : null}
                      onClick={() => {
                        getSessiondetails();
                      }}
                    >
                      {details?.extraDetailsFetch
                        ? details.portsDetails.reduce(
                            (acc, port) => acc + port.totalSessions,
                            0
                          )
                          ? details.portsDetails.reduce(
                              (acc, port) => acc + port.totalSessions,
                              0
                            )
                          : 0
                        : "Click here to load"}
                    </p>
                  ) : (
                    <p className="description">No Ports</p>
                  )}
                </div>
                <div className="single_detail">
                  <p className="c_title">Last heartbeat at:</p>
                  <p className="description">
                    {details?.lastCommunication || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>

                <div className="single_detail">
                  <p className="c_title">Charger commissioned on:</p>
                  <p className="description">
                    {data?.firstCommunication || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>

                <div className="single_detail">
                  <p className="c_title">Depot Billing activated on:</p>
                  <p className="description">
                    {details?.billingActivated || titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
                <div className="single_detail">
                  <p className="c_title">Last assembly line check-in:</p>
                  <p className="description">
                    {details?.lastAssemblyLineCheckIn ||
                      titleLabels.NOT_AVAILABLE}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="ports_details_section">
            <div
              className="ports_heading chargerSidebarContainer"
              onClick={() =>
                setExpanded({ ...isExpanded, ports: !isExpanded.ports })
              }
            >
              <p>PORT DETAILS</p>
              <div className="centerAligned">
                <PrivilegedComponent
                  permission="Manage Port"
                  module="chargerDetails"
                >
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={`This charger model supports a maximum of ${
                      data?.numberOfPorts
                    } port${data?.numberOfPorts > 1 ? "s" : ""}.`}
                  >
                    <span>
                      <Controls.Button
                        text="Port"
                        disabled={
                          details?.portsDetails?.length >= data?.numberOfPorts
                        }
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          let totalCount = details?.portsDetails
                            ?.map((obj) => obj["minimumChargingRate"])
                            ?.reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue,
                              0
                            );

                          if (
                            parseInt(totalCount) >=
                            parseInt(data?.maxChargingRate)
                          ) {
                            setAlertConfirmDialog({
                              isOpen: true,
                              title: "This action is not allowed",
                              subTitle:
                                "Cannot add more ports as the number of ports and capacity of each port reached the max capacity of the charger",
                              type: "secondary",
                            });
                          } else {
                            toggleDetailDrawer(
                              true,
                              "ADD CHARGER PORT",
                              "addChargerPort",
                              {}
                            );
                          }

                          handleClose();
                        }}
                        style={{
                          border: "1px solid #2770D8",
                          padding: "3px 5px",
                        }}
                      />
                    </span>
                  </Tooltip>
                </PrivilegedComponent>
                <div style={{ paddingTop: "5px" }}>
                  {!isExpanded.ports ? (
                    <ExpandMoreIcon className="accordion_arrows" />
                  ) : (
                    <ExpandLessIcon className="accordion_arrows" />
                  )}
                </div>
              </div>
            </div>
            {isExpanded.ports && (
              <>
                {data?.derateConfig?.isChargerDerated && (
                  <div className="chargerDerateBanner">
                    <InfoIcon fontSize="small" className="leftInfoIcon" />
                    <p>
                      All ports are derated to{" "}
                      {data?.derateConfig?.deratedMaxAmps} A{" "}
                      <span className="timestamp">
                        on{" "}
                        {data?.derateConfig?.timestamp?.replace("GMT", "UTC")}
                      </span>
                    </p>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title={
                        "Indicates a hardware derate, which is set by sending a changeConfiguration command to the charger."
                      }
                    >
                      <InfoIcon fontSize="small" className="rightInfoIcon" />
                    </Tooltip>
                  </div>
                )}
                {details?.portsDetails?.length > 0 && (
                  <div className="all_ports_details">
                    {details?.portsDetails?.map((single, index) => {
                      return (
                        <div className="single_port" key={single.portId}>
                          <div className="name_status_section">
                            <div className="name_gid">
                              <p className="port_Name">
                                {single.portName || titleLabels.NOT_AVAILABLE}
                              </p>
                              <p className="gid">
                                {single.connectorId ||
                                  titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>

                            <div className="status_vname">
                              <p className="charged" id="status_one">
                                {single.chargingStatus ||
                                  titleLabels.NOT_AVAILABLE}
                              </p>
                              <div className="vname">
                                {single.isPublicSession === true ? (
                                  <>
                                    <div className="vimg">
                                      <img
                                        src={publicCarImage}
                                        alt="publicCar"
                                        className="bus"
                                      />
                                    </div>
                                    <p className="publicName">Public vehicle</p>
                                  </>
                                ) : (
                                  <>
                                    <div className="vimg">
                                      <img
                                        src={busImage}
                                        alt="Bus"
                                        className="bus"
                                      />
                                    </div>
                                    <p className="name">
                                      {single.vehicleName ||
                                        titleLabels.NOT_AVAILABLE}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div>
                                <PrivilegedComponent
                                  permission="Manage Port"
                                  module="chargerDetails"
                                >
                                  <Tooltip
                                    arrow
                                    TransitionComponent={Zoom}
                                    title={"Edit or Delete Port"}
                                  >
                                    <span>
                                      <Controls.ActionButton
                                        // color="primary"
                                        onClick={(e) => handleClick(e, index)}
                                      >
                                        <MoreVertIcon
                                          fontSize="small"
                                          color={"#1DB954"}
                                        />
                                      </Controls.ActionButton>
                                    </span>
                                  </Tooltip>
                                </PrivilegedComponent>

                                <Menu
                                  id={`basic-menu-${index}`}
                                  anchorEl={anchorEl[index]}
                                  open={Boolean(anchorEl[index])}
                                  onClose={() => handleClose(index)}
                                  MenuListProps={{
                                    "aria-labelledby": `basic-button-${index}`,
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      toggleDetailDrawer(
                                        true,
                                        "EDIT CHARGER PORT",
                                        "editChargerPort",
                                        single
                                      ),
                                        handleClose(index);
                                    }}
                                  >
                                    Edit port details
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      if (
                                        single.chargingStatus === "Not Charging"
                                      ) {
                                        setDeleteConfirmDialog({
                                          isOpen: true,
                                          title: "Delete charger port",
                                          subTitle:
                                            "Are you sure you want to delete the selected charger port?",
                                          type: "secondary",
                                          onConfirm: () =>
                                            deletePort(single, data),
                                        });
                                      } else {
                                        setAlertConfirmDialog({
                                          isOpen: true,
                                          title:
                                            "This port cannot be deleted while a vehicle is plugged into it.",
                                          subTitle:
                                            "This port is plugged into a vehicle. To delete this port, please unplug the vehicle and try again.",
                                          type: "secondary",
                                        });
                                      }

                                      handleClose(index);
                                    }}
                                  >
                                    Delete port
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                          </div>
                          {single?.maxAmps < data?.modelMaxAmps && (
                            <div className="chargerDerateBanner">
                              <InfoIcon
                                fontSize="small"
                                className="leftInfoIcon"
                              />
                              <p>Port is limited to {data?.maxAmps} A</p>
                              <Tooltip
                                arrow
                                TransitionComponent={Zoom}
                                title={
                                  "Indicates a software limit, which is set via Ops Console or Depot Charging software. The software limit is used by the charging algorithm and will not apply if the charger is offline."
                                }
                              >
                                <InfoIcon
                                  fontSize="small"
                                  className="rightInfoIcon"
                                />
                              </Tooltip>
                            </div>
                          )}
                          <div className="type_min_max_section">
                            <div className="type">
                              <p className="title">Min charging rate(kW)</p>
                              <p className="disc">
                                {single.minimumChargingRate >= 0
                                  ? single.minimumChargingRate
                                  : titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                            <div className="max">
                              <p className="title">Max charging rate(kW)</p>
                              <p className="disc">
                                {single.maxCapacity >= 0
                                  ? single.maxCapacity
                                  : titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                          </div>
                          <div className="type_min_max_section">
                            <div className="type">
                              <p className="title">Connector type</p>
                              <p className="disc">
                                {single.typeConnector ||
                                  titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                            <div className="min">
                              <p className="title">
                                Last charging session completed at
                              </p>
                              <p className="disc">
                                {single?.lastSessionCompletedAt
                                  ? `${single?.lastSessionCompletedAt.replace(
                                      "GMT",
                                      "UTC"
                                    )}`
                                  : titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                          </div>
                          <div className="type_min_max_section">
                            <div className="type">
                              <p className="title">Lifetime energy dispensed</p>
                              <p
                                className="disc"
                                style={
                                  !details?.extraDetailsFetch
                                    ? p_tag_hyper
                                    : null
                                }
                                onClick={() => {
                                  getSessiondetails();
                                }}
                              >
                                {single?.kWhDispensed >= 0
                                  ? `${single?.kWhDispensed.toFixed(2)} kWh`
                                  : "Click here to load"}
                              </p>
                            </div>

                            <div className="min">
                              <p className="title">Total charge sessions</p>
                              <p
                                className="disc"
                                style={
                                  !details?.extraDetailsFetch
                                    ? p_tag_hyper
                                    : null
                                }
                                onClick={() => {
                                  getSessiondetails();
                                }}
                              >
                                {single?.totalSessions >= 0
                                  ? single?.totalSessions
                                  : "Click here to load"}
                              </p>
                            </div>
                          </div>
                          <div className="type_min_max_section">
                            <div className="type">
                              <p className="title">OCPI port ID</p>
                              <p className="disc">
                                {single.portObjectId ||
                                  titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                            <div className="min">
                              <p className="title">Description</p>
                              <p className="disc">
                                {single.description ||
                                  titleLabels.NOT_AVAILABLE}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
          {data?.rfidDetails?.rfidConfigMode && (
            <div className="charger_details_section">
              <div
                className="charger_heading chargerSidebarContainer"
                onClick={() =>
                  setExpanded({
                    ...isExpanded,
                    rfidConfigs: !isExpanded.rfidConfigs,
                  })
                }
              >
                <p>
                  {data?.rfidDetails?.rfidConfigMode === "freevend"
                    ? "FREEVEND "
                    : "RFID "}
                  CONFIGURATIONS
                </p>
                {!isExpanded.rfidConfigs ? (
                  <ExpandMoreIcon className="accordion_arrows" />
                ) : (
                  <ExpandLessIcon className="accordion_arrows" />
                )}
              </div>
              {isExpanded.rfidConfigs && (
                <div>
                  <Grid container className="rfidAuthModeHeading">
                    <Grid item xs={5}></Grid>
                    <Grid item xs={4} className="rfidHeading">
                      Local Configs
                    </Grid>
                    <Grid item xs={3} className="rfidHeading">
                      Reference Configs
                    </Grid>
                  </Grid>
                  {rfidConf?.map((row, ind) => (
                    <Grid
                      container
                      key={ind}
                      spacing={0}
                      className="rfidAuthModeRows"
                    >
                      <Grid item xs={5} className="rfidConfKey">
                        <p>{row.key}</p>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          background: !row.isMatch ? "#FEF8EB" : "#FFFFFF",
                        }}
                      >
                        <div
                          style={
                            row.localConf === undefined ? p_tag_hyper : null
                          }
                          onClick={() => {
                            if (row.localConf === undefined)
                              getOCPPConfigsByCharger(
                                data?.depotId,
                                data?.chargerId
                              );
                          }}
                        >
                          {row.localConf === undefined ? (
                            <p>Click here to load</p>
                          ) : (
                            <div className="rfidConfValue">
                              <p className="zeroMargin">{row.localConf}</p>
                              <p className="lastUpdatedTime">
                                {row.timestamp && (
                                  <>
                                    Last updated on: <br /> {row.timestamp}
                                  </>
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={3} className="rfidConfValue">
                        <p>{row.refConf}</p>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <ToastMessage toast={toast} setToast={setToast} />
      {deleteConfirmDialog.isOpen && (
        <DeleteConfirmDialog
          deleteConfirmDialog={deleteConfirmDialog}
          setDeleteConfirmDialog={setDeleteConfirmDialog}
          loading={props.loading}
        />
      )}

      {alertConfirmDialog.isOpen && (
        <AlertDialog
          alertConfirmDialog={alertConfirmDialog}
          setAlertConfirmDialog={setAlertConfirmDialog}
        />
      )}
      <NewCommonFilterDrawer
        DrawerOC={DrawerOC}
        toggleDrawer={toggleDetailDrawer}
      >
        {(DrawerOC.title === "EDIT CHARGER PORT" ||
          DrawerOC.title === "ADD CHARGER PORT") && (
          <AddEditChargerPort
            DrawerOC={DrawerOC}
            parentCharger={data}
            getOtherChargerDetails={getOtherChargerDetails}
            details={details}
            setToast={setToast}
            setLoading={props.setLoading}
            loading={props.loading}
            toggleDetailDrawer={toggleDetailDrawer}
            portCount={details?.portsDetails?.length}
          />
        )}
      </NewCommonFilterDrawer>
    </>
  );
};
export default ChargerdetailsSideBar;
