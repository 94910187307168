import { getTimezoneInHourMin } from "../../../../components/common-functions/CommonFunctions";

const getStartDate = () => {
  const now = new Date();
  const [d, m, y] = now.toLocaleDateString("en-GB").split("/");
  const tz = getTimezoneInHourMin(now);
  const startDate = y + "-" + m + "-" + d + "T00:00:00.000" + tz;
  return startDate;
};

const getEndDate = () => {
  const now = new Date();
  const [d, m, y] = now.toLocaleDateString("en-GB").split("/");
  const yy = +y + 15; // endDate is 15 years after startDate
  const tz = getTimezoneInHourMin(now);
  const endDate = yy + "-" + m + "-" + d + "T23:59:59.999" + tz;
  return endDate;
};

export const chargerProfileTemplates = {
  ukSmartChargeRegulation: {
    isDefault: true,
    scheduleZone: "UK Smart Charge Regulation",
    profileName: " - UK Smart Charge",
    scheduleLabel: "UK SMART CHARGE REGULATION SCHEDULE DETAILS",
    chargingMode: "simple",
    blackoutType: "timeWindow",
    preconditioning: true,
    soc: 100,
    kwh: null,
    chargingSchedule: "8am to 11am and 4pm to 10pm on weekdays",
    dwellTime: 15,
    accessControlSchedules: [
      {
        startsAt: getStartDate(),
        expiresOn: getEndDate(),
        repeatDays: [1, 2, 3, 4, 5], // Mon to Fri
        startTime: 480, // 8am
        stopTime: 660, // 11am
        isActive: true,
      },
      {
        startsAt: getStartDate(),
        expiresOn: getEndDate(),
        repeatDays: [1, 2, 3, 4, 5], // Mon to Fri
        startTime: 960, // 4pm
        stopTime: 1320, // 10pm
        isActive: true,
      },
    ],
  },
  naHomeChargeSettings: {
    isDefault: false,
    scheduleZone: "Home Charge Settings",
    profileName: " - Charge Settings",
    scheduleLabel: "HOME CHARGE SETTING SCHEDULE DETAILS",
    chargingMode: "simple",
    blackoutType: "dwellTime",
    preconditioning: true,
    soc: 100,
    kwh: null,
    chargingSchedule: "No time restriction schedule (charge anytime)",
    dwellTime: 15,
    accessControlSchedules: [],
  },
  unmanaged: {
    isDefault: false,
    soc: 100,
    scheduleZone: "Unmanaged",
    profileName: " - Charge Settings",
    chargingMode: "unmanaged",
    accessControlSchedules: [],
  },
};
