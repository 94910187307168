import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Badge,
  Tooltip,
  Divider,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import InfoIcon from "@mui/icons-material/Info";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Controls from "../../components/controls/Controls";
import NewPageMainContent from "../../components/NewPageMainContent";
import { useDispatch, useSelector } from "react-redux";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import Loader from "../../components/Loader";
import ToastMessage from "../../components/ToastMessage";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import { DepotType, variables } from "../../constants/Variables";
import UserAlertSettingsFilter, {
  VendorErrorCodeFilter,
} from "./UserAlertSettingsFilter";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import "./users.scss";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import AccountSelection from "../reports/accountsSidesDrawer";
import { setData } from "../../utils/Storage";
import { chdActionCreator } from "../../redux-state/actions";
import { bindActionCreators } from "redux";
import { checkSingleFilterSelected } from "../../components/all-filter-types/isFilteredCheckFunctions";
import ActiveFilters from "../../components/all-filter-types/ActiveFilters";

export default function UserAlertSettings() {
  const [activeFilters, setActiveFilters] = useState([]);
  const { model, powerType, depotType, countryCode, homeChargingFlag } =
    useSelector((state) => state.chdFilter);
  const dispatch = useDispatch();
  const { saveCHDFilter, saveAllAccountsAndDepots } = bindActionCreators(
    chdActionCreator,
    dispatch
  );
  const [isDepotFiltered, setIsDepotFiltered] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isErrorCodeFiltered, setIsErrorCodeFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCodesLoading, setErrorCodesLoading] = useState(false);
  const [checked, setChecked] = useState({
    faultAlerts: false,
    selectIndividualFaults: false,
    connectivityAlerts: false,
    isCllmAlertsEnabled: false,
    maxChargerTempAlerts: false,
    minChargerTempAlerts: false,
  });
  const [alertTimeout, setAlertTimeout] = useState(5);
  const [errors, setErrors] = useState("");
  const [errorCodesData, setErrorCodesData] = useState([]);
  const [selectedErrorCodes, setSelectedErrorCodes] = useState([]);
  const [severity, setSeverity] = useState([]);
  const severityList = [
    "All High Severity",
    "All Medium Severity",
    "All Low Severity",
    "All Diagnostic Severity",
    "Not Available",
  ];
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [drawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item });
  };
  const handleFilterDrawer = (title) => {
    setDrawerObj({
      isOpen: true,
      title: title,
      data: {},
    });
  };
  const [depotDrawerObj, setDepotDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const toggleDepotDrawer = (openClose, title, item) => {
    setDepotDrawerObj({ isOpen: openClose, title: title, data: item });
  };
  const handleDepotFilterDrawer = () => {
    setDepotDrawerObj({
      isOpen: true,
      title: "Accounts and Depots",
      data: {},
    });
  };
  const depotTypeList = [
    DepotType.TEST,
    DepotType.INTERNAL,
    DepotType.PILOT,
    DepotType.CUSTOMER,
    DepotType.DEALER,
  ];
  const [depotTypes, setDepotTypes] = useState([
    {
      name: "Depot Type",
      isExpanded: true,
      checked: false,
      children: depotTypeList?.map((item) => ({ checked: false, name: item })),
    },
  ]);
  const [companies, setCompanies] = useState([]);
  const [preferredDepotsList, setPreferredDepotsList] = useState([]);
  const [custAccounts, setCustAccounts] = useState([
    {
      name: "Customers",
      isExpanded: true,
      checked: false,
      children: [],
    },
  ]);
  const [allCustAcc, setAllCustAcc] = useState([]);
  const [custAccReady, setCustAccReady] = useState(false);
  const [errorCodesReady, setErrorCodesReady] = useState(false);

  const userInfo = useSelector((state) => state.user);
  const { userEmail = "" } = userInfo;

  const { USER_ALERT_SETTINGS, GET_ALL_V2_COMPANIES, READ_ERRORS } =
    indexedEndPoints;
  const endPoint = USER_ALERT_SETTINGS.replace("email", userEmail);

  const handleChange = (event, type) =>
    setChecked({ ...checked, [type]: event.target.checked });

  useEffect(() => {
    const fetchCustomerAccounts = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_ALL_V2_COMPANIES);
      response?.data?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            singleChild["checked"] = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: response?.data,
        },
      ];
      setCompanies(tempAccounts);
      setAllCustAcc(
        response?.data?.map((row) => ({
          checked: false,
          name: row.companyName,
          id: row.companyId,
        }))
      );
      setCustAccounts([
        {
          ...custAccounts[0],
          children: response?.data?.map((row) => ({
            checked: false,
            name: row.companyName,
            id: row.companyId,
          })),
        },
      ]);
      setCustAccReady(true);
    };
    fetchCustomerAccounts();
  }, []);

  useEffect(() => {
    const getUniqueVendorErrorCodes = (data) => {
      let vec = data?.map((row) => ({
        checked: false,
        name: row.vendorErrorCode || "Blank - No Vendor Error Code",
        value: row.oid,
      }));
      vec = vec.filter(
        (row, index, array) =>
          array.findIndex(
            (t) => t.name === row.name && t.value === row.value
          ) == index
      ); // remove duplicates from vendorErrorCodes array
      return vec;
    };

    const fetchErrorCodesData = async () => {
      let formattedData = [];
      setErrorCodesLoading(true);
      const res = await apiProvider.getAll(READ_ERRORS);
      if (res?.statusCode >= 200 && res?.statusCode <= 299) {
        const data = res?.data?.body;
        const ecGroups = Object.groupBy(
          data,
          ({ errorCodeGroupId }) => errorCodeGroupId
        );
        formattedData = Object.keys(ecGroups)?.map((key) => {
          const errorCodesObj = Object.groupBy(
            ecGroups[key],
            ({ errorCode }) => errorCode || "Blank - No Error Code"
          );

          let data = {
            checked: false,
            isExpanded: true,
            name: ecGroups[key][0]?.errorCodeGroupName,
            children: Object.keys(errorCodesObj)?.map((item) => ({
              checked: false,
              isExpanded: true,
              name: item,
              children: getUniqueVendorErrorCodes(errorCodesObj[item]),
            })),
          };
          return data;
        });
      }
      setErrorCodesLoading(false);
      setErrorCodesData(formattedData);
      setErrorCodesReady(true);
    };
    fetchErrorCodesData();
  }, []);

  const clearAllFilters = () => {
    const depots = [
      {
        ...depotTypes[0],
        checked: false,
        children: depotTypes[0]?.children?.map((item) => ({
          ...item,
          checked: false,
        })),
      },
    ];
    const custAcc = [
      {
        ...custAccounts[0],
        checked: false,
        children: custAccounts[0]?.children?.map((item) => ({
          ...item,
          checked: false,
        })),
      },
    ];
    setDepotTypes(depots);
    setCustAccounts(custAcc);
    handleSubmit(depots, custAcc);
    setIsFiltered(false);
  };

  React.useEffect(() => {
    const activeFilters = [];
    if (checkSingleFilterSelected(depotTypes)) {
      activeFilters.push({
        name: "Depot Type",
        onClick: (index) => {
          const depots = [
            {
              ...depotTypes[0],
              checked: false,
              children: depotTypes[0]?.children?.map((item) => ({
                ...item,
                checked: false,
              })),
            },
          ];
          setDepotTypes(depots);
          handleSubmit(depots);
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(custAccounts)) {
      activeFilters.push({
        name: "Customer",
        onClick: (index) => {
          const custAcc = [
            {
              ...custAccounts[0],
              checked: false,
              children: custAccounts[0]?.children?.map((item) => ({
                ...item,
                checked: false,
              })),
            },
          ];
          setCustAccounts(custAcc);
          handleSubmit(depotTypes, custAcc);
          activeFilters.splice(index, 1);
        },
      });
    }
    setIsFiltered(activeFilters?.length > 0);
    setActiveFilters(activeFilters);
  }, [depotTypes, custAccounts]);

  const applyFilter = () => {
    const depotsList = createFilterPayload();
    setPreferredDepotsList(depotsList);
    setIsDepotFiltered(depotsList?.length > 0);
    toggleDepotDrawer(false, "", {});
  };

  const createFilterPayload = () => {
    let selectedAccFilters = [];
    if (companies[0].children.length) {
      selectedAccFilters = companies[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    return [...selectedAccFilters];
  };

  const clearAll = () => {
    if (companies[0].children) {
      companies[0].checked = false;
      companies[0].children.map((child) => {
        child.checked = false;
        if (child.depotsList) {
          child.depotsList.map((grandChild) => {
            grandChild.checked = false;
          });
        }
      });
    }
    setCompanies([...companies]);
  };

  const handleAccountsFilterChange = () => {
    setCompanies([...companies]);
  };

  const applyErrorCodeFilter = () => {
    let selectedErrorCodes = [];
    if (errorCodesData?.length) {
      errorCodesData?.map((item) =>
        item.children.map((child) =>
          child.children.map((grandChild) => {
            if (grandChild.checked) selectedErrorCodes.push(grandChild.value);
          })
        )
      );
    }
    setSelectedErrorCodes(selectedErrorCodes);
    setIsErrorCodeFiltered(selectedErrorCodes?.length > 0);
    toggleDrawer(false, "", {});
  };

  const clearAllErrorCodes = () => {
    if (errorCodesData?.length) {
      errorCodesData?.map((item) => {
        item.checked = false;
        if (item?.children?.length) {
          item.children.map((child) => {
            child.checked = false;
            if (child?.children?.length) {
              child.children.map((grandChild) => {
                grandChild.checked = false;
              });
            }
          });
        }
      });
      setErrorCodesData([...errorCodesData]);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await apiProvider.getAll(endPoint);
      setLoading(false);
      const notifications = response?.data?.notifications;
      setIsDepotFiltered(notifications?.preferredDepotsList?.length > 0);
      setPreferredDepotsList(notifications?.preferredDepotsList);
      setIsFiltered(
        notifications?.depotTypes?.length > 0 ||
          notifications?.companyIds?.length > 0 ||
          notifications?.isAllCustomersSelected
      );
      setIsErrorCodeFiltered(notifications?.errorCodeObjectIds?.length > 0);
      setChecked({
        faultAlerts: notifications?.chargerFaults?.isEnabled,
        selectIndividualFaults: notifications?.selectIndividualFaults || false,
        connectivityAlerts: notifications?.chargerConnectivity?.isEnabled,
        isCllmAlertsEnabled: notifications?.isCllmAlertsEnabled || false,
        maxChargerTempAlerts:
          notifications?.chargerTemperature?.maxTemperature || false,
        minChargerTempAlerts:
          notifications?.chargerTemperature?.minTemperature || false,
      });
      setAlertTimeout(notifications?.chargerConnectivity?.intervalInMin);
      const faultSeverity = notifications?.chargerFaults;
      const severityDefaultValues = [];
      faultSeverity?.isHigh && severityDefaultValues.push("All High Severity");
      faultSeverity?.isMedium &&
        severityDefaultValues.push("All Medium Severity");
      faultSeverity?.isLow && severityDefaultValues.push("All Low Severity");
      faultSeverity?.isDiagnostic &&
        severityDefaultValues.push("All Diagnostic Severity");
      faultSeverity?.isNa && severityDefaultValues.push("Not Available");
      setSeverity(severityDefaultValues);
      setDepotTypes([
        {
          ...depotTypes[0],
          checked:
            depotTypes[0]?.children?.length ===
            notifications?.depotTypes?.length,
          children: depotTypes[0]?.children?.map((item) => ({
            ...item,
            checked: notifications?.depotTypes?.includes(item.name),
          })),
        },
      ]);
      custAccounts[0]?.children?.length &&
        setCustAccounts([
          {
            ...custAccounts[0],
            checked: notifications?.isAllCustomersSelected,
            children: custAccounts[0]?.children?.map((item) => ({
              ...item,
              checked:
                notifications?.isAllCustomersSelected ||
                notifications?.companyIds?.includes(item.id),
            })),
          },
        ]);
      companies[0]?.children?.length &&
        setCompanies([
          {
            ...companies[0],
            children: companies[0]?.children?.map((single) => ({
              ...single,
              checked: false,
              isExpanded: true,
              depotsList: single.depotsList?.map((singleChild) => ({
                ...singleChild,
                checked: notifications?.preferredDepotsList?.includes(
                  singleChild?.depotId
                ),
              })),
            })),
          },
        ]);
      errorCodesData?.length &&
        notifications?.errorCodeObjectIds?.length &&
        setErrorCodesData(
          errorCodesData?.map((item) => ({
            ...item,
            children: item.children.map((child) => ({
              ...child,
              children: child.children.map((grandChild) => ({
                ...grandChild,
                checked: notifications?.errorCodeObjectIds?.includes(
                  grandChild.value
                ),
              })),
            })),
          }))
        );
      setSelectedErrorCodes(notifications?.errorCodeObjectIds || []);
    };

    custAccReady && errorCodesReady && fetchSettings();
  }, [custAccReady, errorCodesReady]);

  const handleSubmit = useCallback(
    async (depots = depotTypes, custAcc = custAccounts) => {
      setLoading(true);
      const payload = {
        chargerFaults: {
          isEnabled: checked?.faultAlerts,
          isHigh: severity?.includes("All High Severity"),
          isMedium: severity?.includes("All Medium Severity"),
          isLow: severity?.includes("All Low Severity"),
          isDiagnostic: severity?.includes("All Diagnostic Severity"),
          isNa: severity?.includes("Not Available"),
        },
        chargerConnectivity: {
          isEnabled: checked?.connectivityAlerts,
          intervalInMin: alertTimeout,
        },
        isCllmAlertsEnabled: checked?.isCllmAlertsEnabled,
        chargerTemperature: {
          maxTemperature: checked?.maxChargerTempAlerts,
          minTemperature: checked?.minChargerTempAlerts,
        },
        depotTypes: depots[0]?.children
          ?.filter((item) => item.checked)
          ?.map((item) => item.name),
        companyIds: custAcc[0]?.children
          ?.filter((item) => item.checked)
          ?.map((item) => item.id),
        isAllCustomersSelected:
          custAcc[0]?.children?.filter((item) => item.checked)?.length ===
          allCustAcc?.length,
        preferredDepotsList: preferredDepotsList,
        errorCodeObjectIds: selectedErrorCodes,
        selectIndividualFaults: checked?.selectIndividualFaults,
      };
      const response = await apiProvider.put(endPoint, payload);
      setLoading(false);
      if (response.statusCode >= 200 && response.statusCode <= 299) {
        setData(variables.USER_PREFERRED_DEPOTS, preferredDepotsList);
        // saveCHDFilter and saveAllAccountsAndDepots used to update redux store for CHD
        saveCHDFilter({
          depotId: preferredDepotsList,
          model,
          powerType,
          depotType,
          countryCode,
          homeChargingFlag,
        });
        saveAllAccountsAndDepots(companies);
        setToast({
          isOpen: true,
          message: "User Settings updated successfully",
          type: "success",
        });
      } else {
        setToast({
          isOpen: true,
          message: "User Settings updation failed",
          type: "error",
        });
      }
    },
    [
      checked,
      severity,
      alertTimeout,
      depotTypes,
      custAccounts,
      preferredDepotsList,
      selectedErrorCodes,
    ]
  );

  useEffect(
    () =>
      setErrors(
        checked?.connectivityAlerts && (alertTimeout < 5 || alertTimeout > 1440)
          ? "Please enter duration between 5 minutes and 1440 minutes"
          : ""
      ),
    [checked, alertTimeout]
  );

  return (
    <>
      <NewPageMainContent>
        <OpsBreadcrumb AllBreadcrumbsLinks={[]} title="User Settings" />
        <div className="topPadding">
          <PageHeader title="User Settings" />
        </div>
        <Loader isLoading={loading || errorCodesLoading} />
        <div className="userSettingsContainer">
          <Grid container className="centerAligned" id="userSettingsSection">
            <Grid item>
              <p className="categoryLabel flexCenterDisp">
                Depot Preferences{" "}
                <Tooltip title="The “Select Preferred Depots” feature sets the default depots that appear for you as a user throughout the Ops Console so that you can default to your preferred customers and depots.">
                  <InfoIcon className="infoIcon" />
                </Tooltip>
              </p>
            </Grid>
            <Grid item>
              <Badge
                color="primary"
                variant="dot"
                invisible={isDepotFiltered ? false : true}
              >
                <Controls.Button
                  text="Select Preferred Depots"
                  variant="outlined"
                  startIcon={
                    <TuneIcon
                      style={{ color: isDepotFiltered ? "#2770D8" : "#A3B6C7" }}
                    />
                  }
                  onClick={handleDepotFilterDrawer}
                  style={{
                    border: isDepotFiltered
                      ? "1px solid #2770D8"
                      : "1px solid #A3B6C7",
                    padding: "4px 10px",
                    color: "#3C5164",
                  }}
                />
              </Badge>
            </Grid>
          </Grid>
        </div>
        <div className="userSettingsContainer">
          <Grid
            container
            className="error_code_drawer"
            id="userSettingsSection"
          >
            <Grid item xs={12}>
              <p className="chargerAlertsLabel">Charger Alerts</p>
            </Grid>
            <Grid item>
              <p className="categoryLabel flexCenterDisp">
                Alerts{" "}
                <Tooltip title="The “Charger Alerts” feature sends emails when faults are received for the selected customer and fault criteria.">
                  <InfoIcon className="infoIcon" />
                </Tooltip>
              </p>
            </Grid>
            <Grid item sm />
            <Grid item>
              <ActiveFilters
                filters={activeFilters}
                clearAll={clearAllFilters}
              />
            </Grid>
            <Grid item>
              <Badge
                color="primary"
                variant="dot"
                invisible={isFiltered ? false : true}
              >
                <Controls.Button
                  text="Select Accounts to receive alerts for"
                  variant="outlined"
                  startIcon={
                    <TuneIcon
                      style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }}
                    />
                  }
                  onClick={() => handleFilterDrawer("Customer Accounts")}
                  style={{
                    border: isFiltered
                      ? "1px solid #2770D8"
                      : "1px solid #A3B6C7",
                    padding: "4px 10px",
                    color: "#3C5164",
                  }}
                />
              </Badge>
            </Grid>
            <Divider className="width100" />
            <Grid container className="centerAligned">
              <p className="categoryLabel">Charge Port Fault Alerts</p>
              <Switch
                checked={checked?.faultAlerts}
                onChange={(e) => handleChange(e, "faultAlerts")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <div>
              <p className="categorySublabel severityLabel">
                Severity{" "}
                <Tooltip
                  title={
                    <div>
                      The severity levels selector sends alerts for all faults
                      of that severity level for the selected accounts.
                      <ul>
                        <li>
                          <b>High:</b> A fault that prevents charging. If the
                          issue has cleared, charging may become functional
                          again
                        </li>
                        <li>
                          <b>Medium:</b> A fault that negatively impacts, but
                          does not prevent charging (e.g. charging power reduced
                          due to low or high temparatures)
                        </li>
                        <li>
                          <b>Low:</b> A fault that directly does not impact
                          charging but informs about a change to the charger
                        </li>
                        <li>
                          <b>Diagnostic:</b> A fault that is not displayed in
                          the customer UI or fault alert messages regardless of
                          user settings
                        </li>
                        <li>
                          <b>Unknown:</b> A fault that is displayed to customers
                          although severity level has not been confirmed
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <InfoIcon className="infoIcon" />
                </Tooltip>
              </p>
              {severityList?.map((item, i) => (
                <FormGroup key={"severity-row" + i} className="severityLevels">
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!checked?.faultAlerts}
                        checked={severity?.includes(item)}
                        onChange={() => {
                          let sv = severity?.includes(item)
                            ? severity?.filter((key) => key !== item)
                            : [...severity, item];
                          setSeverity(sv);
                        }}
                      />
                    }
                    label={item}
                  />
                </FormGroup>
              ))}
            </div>
            <Grid container className="centerAligned">
              <Grid item>
                <p className="categoryLabel flexCenterDisp centerAligned">
                  Select Individual Faults{" "}
                  <Tooltip title="The selection you make in the individual faults option will only apply to the selected faults and won't impact your overall All severity alert settings.">
                    <InfoIcon className="infoIcon" />
                  </Tooltip>
                  <Switch
                    checked={checked?.selectIndividualFaults}
                    onChange={(e) => handleChange(e, "selectIndividualFaults")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </p>
              </Grid>
              <Grid item sm />
              <Grid item>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={isErrorCodeFiltered ? false : true}
                >
                  <Controls.Button
                    text="Select Vendor Codes to receive alerts for"
                    variant="outlined"
                    startIcon={
                      <TuneIcon
                        style={{
                          color: isErrorCodeFiltered ? "#2770D8" : "#A3B6C7",
                        }}
                      />
                    }
                    disabled={!checked?.selectIndividualFaults}
                    onClick={() => handleFilterDrawer("Vendor Error Code")}
                    style={{
                      border: isErrorCodeFiltered
                        ? "1px solid #2770D8"
                        : "1px solid #A3B6C7",
                      padding: "4px 10px",
                      color: checked?.selectIndividualFaults
                        ? "#3C5164"
                        : "#A3B6C7",
                    }}
                  />
                </Badge>
              </Grid>
            </Grid>
            <Divider className="width100" />
            <Grid container className="centerAligned">
              <p className="categoryLabel">
                Charge Port Connectivity Loss Alerts
              </p>
              <Switch
                checked={checked?.connectivityAlerts}
                onChange={(e) => handleChange(e, "connectivityAlerts")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid container className="centerAligned">
              <Grid item xs={7}>
                <p className="categoryLabelSecondary">
                  Enter amount of time a charger can be offline before
                  generating an alert:
                </p>
              </Grid>
              <Grid item xs={3}>
                <Controls.Input
                  disabled={!checked?.connectivityAlerts}
                  name="alertTimeout"
                  value={alertTimeout}
                  type="number"
                  onChange={(ev) => setAlertTimeout(ev.target.value)}
                  error={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">minutes</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Divider className="width100" />
            <PrivilegedComponent
              permission="Temperature Alert Settings"
              module="chargerDetails"
            >
              <Grid container className="centerAligned">
                <p className="categoryLabel">
                  Maximum Charger Temperature Alert
                </p>
                <Switch
                  checked={checked?.maxChargerTempAlerts}
                  onChange={(e) => handleChange(e, "maxChargerTempAlerts")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid container className="centerAligned">
                <p className="categoryLabel">
                  Minimum Charger Temperature Alert
                </p>
                <Switch
                  checked={checked?.minChargerTempAlerts}
                  onChange={(e) => handleChange(e, "minChargerTempAlerts")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </PrivilegedComponent>
            <Divider className="width100" />
            <PrivilegedComponent permission="CLLM Alert Settings" module="cllm">
              <Grid container className="centerAligned">
                <p className="categoryLabel">
                  Circuit Level Load Monitoring Alerts
                </p>
                <Switch
                  checked={checked?.isCllmAlertsEnabled}
                  onChange={(e) => handleChange(e, "isCllmAlertsEnabled")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </PrivilegedComponent>
          </Grid>
        </div>
        <div className="userAlertSubmit">
          <Controls.Button
            disabled={errors !== ""}
            type="submit"
            text="Apply"
            onClick={() => handleSubmit(depotTypes, custAccounts)}
          />
        </div>
        <ToastMessage toast={toast} setToast={setToast} />
        <NewCommonFilterDrawer
          DrawerOC={depotDrawerObj}
          toggleDrawer={toggleDepotDrawer}
        >
          <AccountSelection
            clearAll={clearAll}
            applyFilter={applyFilter}
            allAccounts={companies}
            setAllAcconts={handleAccountsFilterChange}
          />
        </NewCommonFilterDrawer>
        <NewCommonFilterDrawer DrawerOC={drawerObj} toggleDrawer={toggleDrawer}>
          {drawerObj?.title === "Customer Accounts" ? (
            <UserAlertSettingsFilter
              depotTypes={depotTypes}
              setDepotTypes={setDepotTypes}
              custAcc={custAccounts}
              setCustAcc={setCustAccounts}
              setIsFiltered={setIsFiltered}
              toggleDrawer={toggleDrawer}
              handleSubmit={handleSubmit}
            />
          ) : (
            <VendorErrorCodeFilter
              allErrorCodes={errorCodesData}
              setAllErrorCodes={setErrorCodesData}
              selectedErrorCodes={selectedErrorCodes}
              applyFilter={applyErrorCodeFilter}
              clearAll={clearAllErrorCodes}
            />
          )}
        </NewCommonFilterDrawer>
      </NewPageMainContent>
    </>
  );
}
