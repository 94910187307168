import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import useTable from "../../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Chip,
  Grid,
  InputAdornment,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../../components/controls/Controls";
import PageMainContent from "../../../components/PageMainContent";
import { routePaths } from "../../../constants/RoutePaths";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import Popup from "../../../components/Popup";
import ToastMessage from "../../../components/ToastMessage";
import { AddEditPlan } from "./AddEditProvider";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ViewPlanRates from "./ViewPlanRates";

const allBreadcrumbsLinks = [
  {
    link: routePaths.DATA_CATALOG,
    title: "Data Catalog",
  },
  {
    link: routePaths.UTILITY_RATES,
    title: "Utility Providers",
  },
];

const useStyles = makeStyles(() => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

export default function UtilityPlan() {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [refreshPlans, setRefreshPlans] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "Add Plan",
    child: "",
    item: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const componentPrivileges = useSelector(
    (state) => state?.user?.componentPrivilege?.dataCatalogUtilityRate
  );

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const providerMetaId = searchParams.get("providerId");
  const providerName = searchParams.get("providerName");
  const planId = searchParams.get("planId"); // planId will be available when user navigates from expiry status modal in provider dashboard

  const classes = useStyles();

  const handleEditPlan = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Plan",
      child: "updatePlan",
      item: row,
    });
  };

  const handleDeletePlan = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Are you sure ?",
      subTitle: "You want to Delete this Plan",
      type: "secondary",
      onConfirm: () => deletePlan(row.planMetaId),
    });
  };

  const deletePlan = useCallback(async (planMetaId) => {
    setLoading(true);
    const response = await apiProvider.del(`${UTILITY_PLANS}/${planMetaId}`);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Utility Plan deleted successfully",
        type: "success",
      });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      setRefreshPlans(true);
    } else {
      setToast({
        isOpen: true,
        message: response?.data?.data || "Utility Plan deletion failed",
        type: "error",
      });
    }
  }, []);

  const handleViewMoreInfo = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Utility Rates",
      child: "viewPlanRates",
      item: row?.planMetaId,
    });
  };

  const actionItems = useMemo(() => {
    let actions = [];
    if (componentPrivileges?.includes("Update Plan"))
      actions.push({
        type: "Edit",
        label: "Edit",
        testid: "editUtilityPlanBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Edit">
            <EditOutlined fontSize="small" />
          </Tooltip>
        ),
        onClick: handleEditPlan,
      });
    if (componentPrivileges?.includes("Delete Plan"))
      actions.push({
        type: "Delete",
        label: "Delete",
        testid: "deleteUtilityPlanBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="Delete">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleDeletePlan,
      });
    if (componentPrivileges?.includes("View Plan Rates"))
      actions.push({
        type: "View Plan Rates",
        label: "View Plan Rates",
        testid: "viewUtilityPlanRatesBtn",
        icon: () => (
          <Tooltip arrow TransitionComponent={Zoom} title="View Plan Rates">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
        onClick: handleViewMoreInfo,
      });
    return actions?.length > 0 ? actions : null;
  }, [componentPrivileges]);

  const headCells = useMemo(() => {
    let headers = [
      {
        id: "planName",
        label: "Plan Name",
        styles: { width: "280px" },
        render: (row, col) =>
          row?.planMetaId === planId ? <b>{row[col]}</b> : row[col], // show planName in bold when user navigates from expiry status modal in provider dashboard
      },
      {
        id: "expiryDate",
        label: "Expiry Date",
        render: (row, col) =>
          row[col] ? row[col]?.split(" ")?.slice(0, 4)?.join(" ") : "",
      },
    ];
    if (actionItems)
      headers.push({ disableSorting: true, id: "action", label: "Action" });
    return headers;
  }, [actionItems]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
    page,
    rowsPerPage,
    orderBy,
    order,
  } = useTable(
    data,
    headCells,
    filterFn,
    false,
    true,
    actionItems,
    null,
    totalCount,
    true,
    10
  );

  const { UTILITY_PLANS } = indexedEndPoints;

  useEffect(() => setRefreshPlans(true), [page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    const fetchPlans = async (searchText) => {
      let payload = searchText?.length
        ? `?page=0&count=${rowsPerPage}&search=${searchText}`
        : `?page=${page}&count=${rowsPerPage}`;

      if (orderBy && order) {
        payload += `&orderBy=${orderBy}&order=${order == "asc" ? "1" : "-1"}`;
      }
      setLoading(true);
      const response = await apiProvider.getAll(
        `${UTILITY_PLANS}/${providerMetaId}${payload}`
      );
      setLoading(false);
      setData(response?.data?.data);
      setTotalCount(response?.data?.count);
      setRefreshPlans(false);
    };
    refreshPlans && fetchPlans(searchString);
  }, [refreshPlans, searchString]);

  const ToolbarMemoised = useCallback(({ classes }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className="searchInput"
          testid="inputSearchUtilityPlanBtn"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <Controls.Button
          type="button"
          text="Search"
          size="small"
          data-testid="searchUtilityPlanBtn"
          onClick={() => setRefreshPlans(true)}
        />
        <Grid sm item />
        <PrivilegedComponent
          permission="Add Plan"
          module="dataCatalogUtilityRate"
        >
          <Controls.Button
            text="Add Plan"
            variant="outlined"
            data-testid="addUtilityPlanBtn"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Add Plan",
                child: "addPlan",
                item: {},
              })
            }
          />
        </PrivilegedComponent>
      </Toolbar>
    );
  }, []);

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Utility Plans"
      />
      <PageHeader title="Utility Plans" />
      <PageMainContent>
        <Loader isLoading={loading} />
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Provider Name</p>
              <Controls.CopyToClipboard
                name={providerName}
                setToast={setToast}
              />
            </div>
          </Grid>
        </Grid>
        <ToolbarMemoised classes={classes} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      {openPopup.child === "viewPlanRates" ? (
        <ViewPlanRates openPopup={openPopup} setOpenPopup={setOpenPopup} />
      ) : (
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="xl">
          <AddEditPlan
            type={openPopup.child}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setRefreshPlans={setRefreshPlans}
            setToast={setToast}
            providerName={providerName}
            providerMetaId={providerMetaId}
            planMetaId={openPopup?.item?.planMetaId}
          />
        </Popup>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
