import axios from "axios";
import { handleError, handleResponse } from "./response";
import { getAccessToken } from "../../../utils/Storage";
import environment from "../../../environment";

export const BASE_URL =
  environment[process.env.REACT_APP_NODE_ENV].BACK_END_URL;

export const indexedEndPoints = {
  GET_ALL_V2_COMPANIES: `${BASE_URL}/ford/v2/companies`,
  GET_MAP_DEPOTS: `${BASE_URL}/platform/analytics/map`,
  DEPOTS: `${BASE_URL}/depots`,
  ACTIVE_DEPOTS: `${BASE_URL}/chargers/v2/active-depots`,
  PLATFORM_INSTANCES: `${BASE_URL}/platform/instances/`,
  START_PLATFORM_INSTANCES: `${BASE_URL}/platform/instances/start/`,
  STOP_PLATFORM_INSTANCES: `${BASE_URL}/platform/instances/stop/`,
  PLATFORM_SERVICES: `${BASE_URL}/platform/services/`,
  PLATFORM_SERVICES_RESTART: `${BASE_URL}/platform/services/restart-service`,
  PLATFORM_SERVICES_INFO: `${BASE_URL}/platform/services/platform-info`,
  PLATFORM_SERVICES_SYS_PERF: `${BASE_URL}/platform/services/system-performance`,
  PLATFORM_CUSTOMERS: `${BASE_URL}/platform/customers/`,
  PLATFORM_CUSTOMERS_ACCOUNTS: `${BASE_URL}/platform/customers/accounts`,
  DEPOT_REQUESTS: `${BASE_URL}/ford/depot-requests`,
  COMPANIES: `${BASE_URL}/ford/companies`,
  DECLINED_REQUESTS: `${BASE_URL}/ford/declined-requests`,
  PROVISION: `${BASE_URL}/depots/v2/provision`,
  DEPOT_SYNCHRONISE: `${BASE_URL}/depots/depotId/synchronize`,
  BILLING_RATEPLANS: `${BASE_URL}/billing/rateplans`,
  DEPOTS_INFO: `${BASE_URL}/depots/depots-info`,
  ANALYTICS_DASHBOARD: `${BASE_URL}/platform/analytics/dashboard`,
  OVERALL_ANALYTICS: `${BASE_URL}/platform/analytics/v2/overall`,
  PERIODICAL_ANALYTICS: `${BASE_URL}/platform/analytics/v2/periodical`,
  CHARGING_MODE: `${BASE_URL}/platform/analytics/ports-charging-mode`,
  PORT_SUMMARY: `${BASE_URL}/platform/analytics/ports/summary`,
  INSIGHTS: `${BASE_URL}/platform/analytics/insights`,
  CHARGER_TYPE_COUNT: `${BASE_URL}/platform/analytics/v2/chargers-type-count`,
  GET_CONNECTIVITY_DETAILS: `${BASE_URL}/api/v1/dashboard/connectivity/data`,
  UPDATE_FAULTS_STATUS: `${BASE_URL}/faults`,
  UPDATE_FAULTS_STATUS_V2: `${BASE_URL}/faults/v2`,
  GET_MANAGED_PORTS: `${BASE_URL}/platform/analytics/overall/ports`,
  CHD_FAULTS_COUNT: `${BASE_URL}/faults/count`,
  CHD_FAULTS_COUNT_V2: `${BASE_URL}/faults/v2/count`,
  CHD_FAULTS_PORTS_COUNT: `${BASE_URL}/faults/charge-port-count`,
  CHD_OPEN_FAULTS: `${BASE_URL}/faults`,
  CHD_OPEN_FAULTS_V2: `${BASE_URL}/faults/v2`,
  FAULTS_EXPORT_CSV: `${BASE_URL}/faults/export-csv`,
  CHD_DOWNLOAD_REPORT: `${BASE_URL}/charger-health/hardware-report`,
  // CHD_TEMPERATURE: `${BASE_URL}/charger-health/v2/temperature`,
  CHD_CONNECTIVITY_NOTES: `${BASE_URL}/charger-health/connectivity-notes`,
  CHD_FAULT_NOTES: `${BASE_URL}/charger-health/fault-notes`,
  GET_ALL_TROUBLESHOOT: `${BASE_URL}/errorcodes`,
  GET_CHARGERS_FIRMWARE_DETAILS: `${BASE_URL}/chargers/v2`,
  GET_CHARGERS_TOTAL_COUNT: `${BASE_URL}/chargers/count`,
  CHARGERS_SERIAL_NO: `${BASE_URL}/chargers/search`,
  CHARGERS_EXPORT_CSV: `${BASE_URL}/chargers/export-csv`,
  GET_CHARGERS_HISTORY: `${BASE_URL}/chargers/history`,
  GET_CHARGERS_PORTS_COUNTS: `${BASE_URL}/chargers/chargers-ports-count`,
  GET_FIRMWARE_DETAILS_COUNT: `${BASE_URL}/chargers/v2/firmware-stats`,
  DISCONNECT_CHARGER: `${BASE_URL}/chargers/depotId/objectId/disconnect`,
  HOME_CHARGING_REGISTRATION: `${BASE_URL}/chargers/register-charger?chargerMicroservices=true`,
  CHARGER_REGISTRATION_HISTORY: `${BASE_URL}/chargers/inventory-register-history`,
  CHARGER_REPLACEMENT: `${BASE_URL}/chargers/replace`,
  CHARGER_REPLACEMENT_HISTORY: `${BASE_URL}/chargers/replace/history`,
  CHARGER_CREDENTIAL: `${BASE_URL}/chargers/credential`,
  GET_DEPOT_DETAILS: `${BASE_URL}/depots/id/details`,
  GET_REPORTS_HISTORY: `${BASE_URL}/reports/history`,
  POST_REPORTS_GENERATE: `${BASE_URL}/reports/v3/generate`,
  GENERATE_REPORTS: `${BASE_URL}/reports/generate`,
  DATA_CATALOG_COUNTS: `${BASE_URL}/platform/analytics/data-catalog/count`,
  ERROR_CODE_GROUPS: `${BASE_URL}/errorcodes/group`,
  ERROR_CODES_IN_GROUP: `${BASE_URL}/errorcodes/group/id/details`,
  MODELS_IN_GROUP: `${BASE_URL}/errorcodes/group/id/models`,
  ADD_ERROR_CODE: `${BASE_URL}/errorcodes/inserterrorcode`,
  ERROR_CODE_LANGUAGES: `${BASE_URL}/errorcodes/language`,
  ERROR_CODE: `${BASE_URL}/errorcodes`,
  AGG_ERROR_CODES: `${BASE_URL}/errorcodes/aggregate`,
  FILTERED_ERROR_CODES: `${BASE_URL}/errorcodes/specificerror`,
  ADD_CHARGER_MANUFACTURER: `${BASE_URL}/chargers-meta/manufacturer`,
  ADD_VEHICLE_MANUFACTURER: `${BASE_URL}/vehicles-meta/manufacturer`,
  GET_CHARGERS: `${BASE_URL}/chargers-meta/manufacturers`,
  GET_VEHICLES: `${BASE_URL}/vehicles-meta/manufacturers`,
  CHARGER_MODEL_FIRMWARES: `${BASE_URL}/chargers-meta/firmware/manufacturer`,
  CHARGERS: `${BASE_URL}/chargers-meta`,
  SOLD_AS_CHARGERS: `${BASE_URL}/i18n-charge-port-models-extended`,
  VEHICLES: `${BASE_URL}/vehicles-meta`,
  CHARGER_FIRMWARE: `${BASE_URL}/chargers-meta/firmware`,
  GET_VIN_VID_MAPPING: `${BASE_URL}/vintovid/readvintovid`,
  ADD_VIN_VID_MAPPING: `${BASE_URL}/vintovid/insertvintovid`,
  VIN_VID: `${BASE_URL}/vintovid`,
  GET_UTILITY_PROVIDERS: `${BASE_URL}/utility-rates/utilityproviders/count`,
  UTILITY_PROVIDERS: `${BASE_URL}/utility-rates/utilityproviders`,
  UTILITY_PLANS: `${BASE_URL}/utility-rates/utilityplans`,
  UTILITY_PLAN_RATES: `${BASE_URL}/utility-rates/planrates`,
  UTILITY_PROVIDER_REGIONS: `${BASE_URL}/utility-rates/utilityproviders/countries`,
  UTILITY_PLAN_SEARCH: `${BASE_URL}/utility-rates/zipcodes`,
  UTILITY_PLAN_EXPIRY_STATUS: `${BASE_URL}/utility-rates/utilityplans/expirystatus`,
  PRE_PROVISIONING: `${BASE_URL}/chargers/v2/pre-provisioning`,
  AUTH_USERS: `${BASE_URL}/auth/users`,
  ROLES: `${BASE_URL}/roles`,
  USER_LOGIN: `${BASE_URL}/auth/users/login`,
  USER_REGISTER: `${BASE_URL}/auth/users/register`,
  USER_ALERT_SETTINGS: `${BASE_URL}/auth/users/email/notifications`,
  READ_ERRORS: `${BASE_URL}/errorcodes/readerrors`,
  CHARGER_UPTIME_METRICS: `${BASE_URL}/chargers/v2/uptime`,
  CHARGER_EXTRA_DETIALS: `${BASE_URL}/chargers`,
  USER_UPDATE_PASSWORD: `${BASE_URL}/auth/users/update-password`,
  USER_FORGOT_PASSWORD: `${BASE_URL}/auth/users/forgot-password/`,
  GET_SERVICE_COMPANIES: `${BASE_URL}/ford/companies`,
  SERVICE_API_SIGNATURE: `${BASE_URL}/internal-apis/CS`,
  SERVICE_CHARGERS: `${BASE_URL}/internal-apis/chargers`,
  SERVICE_BULK_FIRMWARE_LAST_ATTEMPTED: `${BASE_URL}/internal-apis/get-firmware-update`,
  SERVICE_BULK_FIRMWARE_REQ_DETAILS: `${BASE_URL}/internal-apis/bulk-firmware-request-details`,
  SCHEDULED_SERVICES: `${BASE_URL}/scheduler/fetch`,
  SERVICE_HISTORY: `${BASE_URL}/internal-apis/history`,
  SERVICE_EXECUTE: `${BASE_URL}/internal-apis/execute`,
  SERVICE_BULK_EXECUTE: `${BASE_URL}/internal-apis/bulk-execute`,
  SERVICE_EXECUTE_V2: `${BASE_URL}/internal-apis/v2/execute`,
  SERVICE_BULK_EXECUTE_V2: `${BASE_URL}/internal-apis/v2/bulk-execute`,
  UPDATE_FIRMWARE_EXECUTE: `${BASE_URL}/scheduler/add-job`,
  SERVICE_DEPOTS: `${BASE_URL}/company/id/depots`,
  GET_ALL_DEMAND_RESPONSE_PROGRAMS: `${BASE_URL}/depots/demand-response-programs`,
  SEND_LEAP_CONNECT_URL: `${BASE_URL}/depots/email-leapconnecturl`,
  UPDATE_DEPOTS: `${BASE_URL}/depots/update`,
  UPDATE_SPECIFIC_DEPOT: `${BASE_URL}/depots/id/update`,
  BILLING_HISTORY: `${BASE_URL}/billing/id/history`,
  DEPOT_STATUS_HISTORY: `${BASE_URL}/depots/depotId/statusHistory`,
  USAGE: `${BASE_URL}/billing/usages`,
  BILLING: `${BASE_URL}/ford/billing`,
  COMPANY: `${BASE_URL}/company`,
  FORD_COMPANY: `${BASE_URL}/ford/company`,
  CHARGER_PROFILE: `${BASE_URL}/chargers`,
  ADD_CHARGER_PROFILE: `${BASE_URL}/chargers/profile`,
  ADD_CHARGER: `${BASE_URL}/chargers/v2/charger/`,
  CHARGER_SIM_DETAILS: `${BASE_URL}/chargers/chargerId/network-info`,
  COMPANY_PORTS: `${BASE_URL}/company/id/ports`,
  BILLING_DEPOTS: `${BASE_URL}/billing/depots-info`,
  UPDATE_BILLING_DEPOTS: `${BASE_URL}/billing/v2/depots-info`,
  BILLING_COMPANIES: `${BASE_URL}/billing/v2/companies`,
  V3_BILLING_COMPANIES: `${BASE_URL}/billing/v3/companies`,
  BILLING_PACKAGE: `${BASE_URL}/billing/package`,
  BILLABLE_PORTS: `${BASE_URL}/billing/v2/billable-ports`,
  BILLABLE_CUSTOMERS_COUNT: `${BASE_URL}/billing/v2/billable-customers-count`,
  DEPOTS_CHARGING_MODE_CHARGER_COUNT: `${BASE_URL}/depots/charging-mode-charger-count`,
  CHARGER_UTILIZATION: `${BASE_URL}/platform/analytics/charger-utilization`,
  CHARGER_CONNECTIVITY_V2: `${BASE_URL}/chargers/connectivity-data`,
  CHARGER_UTILIZATION_HOURLY: `${BASE_URL}/platform/analytics/charger-utilization-hourly`,
  VEHICLES_DISCOVERY: `${BASE_URL}/vehicles/discovery`,
  VEHICLES_DISCOVERY_METRICS: `${BASE_URL}/vehicles/discovery-metrics`,
  VEHICLES_DISCOVERY_IMPROVEMENT_METRICS: `${BASE_URL}/vehicles/improvement-metrics`,
  VEHICLES_DISCOVERY_CHECK_VIN_VID_MAPPING: `${BASE_URL}/vehicles/check-vin-vid-mapping-exists`,
  VEHICLES_DISCOVERY_FETCH_DATADOG_LOGS: `${BASE_URL}/vehicles/fetch-datadog-log-for-session`,
  FDC_USER_ALERT_METRICS: `${BASE_URL}/faults/subscription-metrics`,
  REJECT_DELETE: `${BASE_URL}/depots/reject-delete`,
  DELETE_DEPOT: `${BASE_URL}/depots`,
  DEPOT_CHARGERS: `${BASE_URL}/chargers/depot-chargers`,
  CHARGER_META_FIRMWARE: `${BASE_URL}/chargers-meta/firmware/`,
  DATA_CATALOG_HISTORY: `${BASE_URL}/errorcodes/history`,
  DATA_CATALOG_MONTH_HISTORY: `${BASE_URL}/errorcodes/month-history`,
  COMPANIES_V3_API: `${BASE_URL}/company/basic-details`,
  COMPANIES_EXPORT_CSV: `${BASE_URL}/company/export-csv`,
  SERVICE_HISTORY_EXPORT_CSV: `${BASE_URL}/internal-apis/history/export-csv`,
  BILLING_COMPANY_DETAILS: `${BASE_URL}/billing/id/depots`,
  GET_REPORTS_COLUMNS: `${BASE_URL}//reports/get-columns/`,
  GET_DRIVERS: `${BASE_URL}/chargers/drivers/`,
  GET_CIRCUITS: `${BASE_URL}/`,
  LOAD_MONITOR_GET_CIRCUIT: `${BASE_URL}/load-monitor`,
  GET_LOAD_MONITOR: `${BASE_URL}/load-monitor/graph`,
  GET_LOAD_MONITOR_ALERT: `${BASE_URL}/load-monitor/alert/history`,
  GET_DATASYNC_EVENTS: `${BASE_URL}/datasync/events`,
  RETRY_DATASYNC_EVENT: `${BASE_URL}/datasync/events/process`,
  PLACE_DETAILS: `${BASE_URL}/platform/analytics/place-details`,
  GET_UPDATED_STATUS: `${BASE_URL}/internal-apis/v2/history/`,
  DISCONNECT_CHARGER_WEBSOCKET: `${BASE_URL}/chargers/depotId/objectId/disconnect-websocket`,
  SHARED_CHARGING: `${BASE_URL}/depots/depotId/shared-charging`,
};

const getHeaders = async () => {
  const token = await getAccessToken();
  return {
    Authorization: "Bearer " + token?.accessToken,
    "Content-Type": "application/json",
  };
};

const getAll = async (resource) => {
  const params = {
    method: "get",
    url: resource,
  };
  return await apiCall(params);
};

const post = async (resource, payload) => {
  const params = {
    method: "post",
    url: resource,
    data: payload,
  };
  return await apiCall(params);
};

const put = async (resource, payload) => {
  const params = {
    method: "put",
    url: resource,
    data: payload,
  };
  return await apiCall(params);
};

const del = async (resource, payload) => {
  const params = {
    method: "delete",
    url: resource,
    data: payload,
  };
  return await apiCall(params);
};

const apiCall = async (params) =>
  await axios(params).then(handleResponse).catch(handleError);

export const apiProvider = {
  getAll,
  post,
  put,
  del,
  getHeaders,
};
