import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import OpsBreadcrumb from "../../../../components/NewBreadcrumbs";
import NewCommonFilterDrawer from "../../../../components/NewSideFIlterDrawer";
import PageMainContent from "../../../../components/PageMainContent";
import { getParamsUseQuery } from "../../../../utils/GetParams";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../../../redux-state/actions";
import {
  getAllConnectivityDetails,
  offlineNotes,
} from "../../ChargerHealthService";
import ConnectivityFilter from "./NewConnectivityFilter";
import { OfflinePortsContext } from "./OfflinePortsContext";
import OfflinePortsTable from "./OfflinePortsTable";
import {
  allDepotTypesForFilter,
  allOfflineDurationFilter,
  allPowerTypeFilter,
} from "../../../../constants/FilterConstants";
import {
  getCheckedValues,
  getCountryCodesByRegion,
} from "../../../../components/common-functions/CommonFunctions";
import { getData } from "../../../../utils/Storage";
import { variables } from "../../../../constants/Variables";
import {
  checkChargersSelected,
  checkDepotSelected,
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../../../components/all-filter-types/isFilteredCheckFunctions";
const AllBreadcrumbsLinks = [
  {
    link: "/charger-health",
    title: "Charger Health",
  },
];

const ConnectivityOfflinePorts = (props) => {
  let preferredDepotsList = getData(variables.USER_PREFERRED_DEPOTS);
  preferredDepotsList = preferredDepotsList?.length
    ? preferredDepotsList?.split(",")
    : null;
  const getCountryCodes = getCountryCodesByRegion(
    JSON.parse(getData(variables.USER_REGION))
  );
  const [activeFilters, setActiveFilters] = useState([]);
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );

  const { depotId, model, make, powerType, depotType, countryCode } =
    useSelector((state) => state.chdFilter);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [tableLoaded, setTableLoaded] = React.useState(false);
  const [offLinePorts, setOffLinePorts] = React.useState([]);
  const [correctData, setCorrectData] = React.useState(false);
  const [offlinePortsRawData, setOfflinePortsRawData] = React.useState([]);
  const [companiesRawData, setCompaniesRawData] = React.useState([]);
  const [depotsRawData, setDepotsRawData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [allPowerType, setAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerTypeFilter))
  );
  const [allDepotType, setAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );
  const [countryCodes, setCountryCodes] = React.useState(getCountryCodes);
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });

  let depotIdParam = getParamsUseQuery().get("depotId");
  let timeSlot = getParamsUseQuery().get("timeSlot")
    ? getParamsUseQuery().get("timeSlot").replace(/\s/g, "")
    : getParamsUseQuery().get("timeSlot");

  const [allAccounts, setAllAcconts] = React.useState([]);
  const [allMakes, setAllMakes] = React.useState([]);
  const [allOpenDuration, setAllOpenDuration] = useState(
    JSON.parse(JSON.stringify(allOfflineDurationFilter))
  );

  React.useEffect(() => {
    if (depotsRawData.length !== 0) {
      getConnectivityData();
    }
  }, [depotsRawData]);
  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilter();
  }, [isChargersMetaLoaded]);

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAccountsAndFilter();
  }, [isCompaniesLoaded]);

  const getConnectivityData = () => {
    let newFilterObject = {};
    if (depotIdParam || depotId) {
      newFilterObject["depotId"] = depotIdParam ? [depotIdParam] : [...depotId];
    }

    if (model) {
      newFilterObject["soldAsIdList"] = model;
    }

    if (powerType) {
      newFilterObject["powerType"] = [...powerType];
      allPowerType[0].children.map((single) =>
        powerType.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllPowerType([...allPowerType]);
    }

    if (countryCode.length !== 0) {
      newFilterObject["countryCode"] = [...countryCode];
      countryCodes[0]?.children?.map((parent) => {
        parent?.children?.map((child) => {
          if (countryCode?.includes(child?.value)) {
            child.checked = true;
          }
        });
      });
      setCountryCodes([...countryCodes]);
    }

    if (depotType.length !== 0) {
      newFilterObject["depotType"] = [...depotType];
      allDepotType[0].children.map((single) =>
        depotType.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllDepotType([...allDepotType]);
    }

    if (timeSlot) {
      if (timeSlot === "30mins") {
        newFilterObject["time"] = [{ low: "0m", high: "30m" }];
        changeOpenDurationInFilter("0 - 30 mins");
      }
      if (timeSlot === "1hr") {
        newFilterObject["time"] = [{ low: "30m", high: "1h" }];
        changeOpenDurationInFilter("30mins - 1 hour");
      }
      if (timeSlot === "2hr") {
        newFilterObject["time"] = [{ low: "1h", high: "2h" }];
        changeOpenDurationInFilter("1 - 2 hours");
      }
      if (timeSlot === "4hr") {
        newFilterObject["time"] = [{ low: "2h", high: "4h" }];
        changeOpenDurationInFilter("2 - 4 hours");
      }
      if (timeSlot === "8hr") {
        newFilterObject["time"] = [{ low: "4h", high: "8h" }];
        changeOpenDurationInFilter("4 - 8 hours");
      }
      if (timeSlot === "12hr") {
        newFilterObject["time"] = [{ low: "8h", high: "12h" }];
        changeOpenDurationInFilter("8 - 12 hours");
      }
      if (timeSlot === "24hr") {
        newFilterObject["time"] = [{ low: "12h", high: "24h" }];
        changeOpenDurationInFilter("12 - 24 hours");
      }
      if (timeSlot === "48hr") {
        newFilterObject["time"] = [{ low: "24h", high: "48h" }];
        changeOpenDurationInFilter("24 - 48 hours");
      }
      if (timeSlot === "1week") {
        newFilterObject["time"] = [{ low: "48h", high: "7d" }];
        changeOpenDurationInFilter("48 hours - 1 week");
      }
      if (timeSlot === "1month") {
        newFilterObject["time"] = [{ low: "7d", high: "30d" }];
        changeOpenDurationInFilter("1 week - 1 month");
      }
      if (timeSlot === "2months") {
        newFilterObject["time"] = [{ low: "30d", high: "60d" }];
        changeOpenDurationInFilter("1 month - 2 months");
      }
      if (timeSlot === "3months") {
        newFilterObject["time"] = [{ low: "60d", high: "180d" }];
        changeOpenDurationInFilter("2 months - 3+ months");
      }
    }
    getConnectivityOfflineWithDepots(newFilterObject);
  };
  const changeOpenDurationInFilter = (tochage) => {
    allOpenDuration[0].children[
      allOpenDuration[0].children.findIndex((x) => x.name == tochage)
    ].checked = true;
    setAllOpenDuration([...allOpenDuration]);
  };

  React.useEffect(() => {
    const tempData = [];
    if (tableData) {
      tableData.map((single) => {
        single["accountName"] =
          companiesRawData.find((x) => x.companyId === single.customerId) ===
          undefined
            ? "-"
            : companiesRawData.find((x) => x.companyId === single.customerId)
                .companyName;
        single["depotName"] =
          depotsRawData.find((x) => x.depotId === single.depotId) === undefined
            ? "-"
            : depotsRawData.find((x) => x.depotId === single.depotId).depotName;
        tempData.push(single);
      });
      setOffLinePorts(tempData);
    }
  }, [tableData, depotsRawData]);

  const getConnectivityOfflineWithDepots = async (filter = {}) => {
    setSelectedFilter(JSON.parse(JSON.stringify(filter)));
    let tempFilter = JSON.parse(JSON.stringify(filter));
    setTableLoaded(false);
    const res = await getAllConnectivityDetails(tempFilter);
    const notesReponse = await offlineNotes();
    if (res?.statusCode === 200 && notesReponse?.statusCode === 200) {
      setOfflinePortsRawData(res?.data);
      let allList = [].concat.apply([], Object.values(res?.data));
      const notesData = notesReponse?.data;
      allList = allList.map((itm) => {
        return {
          ...notesData.find(
            (item) => item.connectivityId === itm.connectivityId && item
          ),
          ...itm,
        };
      });
      setTableData(allList);
      setCorrectData(true);
    } else {
      setCorrectData(false);
    }
    setTableLoaded(true);
  };

  const getAccountsAndFilter = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      setCompaniesRawData(tempAllCompaniesV2);
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      setDepotsRawData([...onlyDepotsList]);
      setCorrectData(true);

      tempAllCompaniesV2.map((single) => {
        single.checked = true;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            if (depotIdParam) {
              if (singleChild.depotId == depotIdParam) {
                singleChild["checked"] = true;
              } else {
                singleChild["checked"] = false;
              }
            } else {
              if (depotId?.length) {
                if (depotId.includes(singleChild.depotId)) {
                  singleChild["checked"] = true;
                } else {
                  singleChild["checked"] = false;
                }
              } else {
                singleChild["checked"] =
                  preferredDepotsList?.length > 0 &&
                  preferredDepotsList?.includes(singleChild.depotId);
              }
            }
            if (singleChild["checked"] === false) single.checked = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAcconts(tempAccounts);
    } else setCorrectData(false);
    setTableLoaded(true);
  };

  const getChargersFilter = async () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            singleChild["checked"] = false;
            singleChild.isExpanded = true;
            if ("soldAs" in singleChild) {
              singleChild?.soldAs?.map((singleSoldAs) => {
                if (model) {
                  if (model.includes(singleSoldAs.modelId)) {
                    singleSoldAs.checked = true;
                  } else {
                    singleSoldAs.checked = false;
                  }
                }
              });
              if (
                getCheckedValues(singleChild?.soldAs, "checked")?.length ==
                singleChild?.soldAs?.length
              ) {
                singleChild.checked = true;
              }
            }
          });
          if (
            getCheckedValues(single?.chargers, "checked")?.length ==
            single?.chargers?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };

  const resetAccountsFilter = () => {
    const allAcc = [...allAccounts];
    allAcc[0].checked = false;
    allAcc[0].children.map((child) => {
      child.checked = false;
      if (child.depotsList) {
        child.depotsList.map((grandChild) => {
          grandChild.checked = false;
        });
      }
    });
    return allAcc;
  };

  const resetMakeModel = () => {
    const makeModel = [...allMakes];
    makeModel[0].checked = false;
    makeModel[0].children.map((child) => {
      child.checked = false;
      if (child.chargers) {
        child.chargers.map((grandChild) => {
          grandChild.checked = false;
          if (grandChild.soldAs) {
            grandChild.soldAs.map((grandGrandChild) => {
              grandGrandChild.checked = false;
            });
          }
        });
      }
    });
    return makeModel;
  };

  const clearAllFilters = () => {
    applyFilter(
      resetAccountsFilter(),
      resetMakeModel(),
      JSON.parse(JSON.stringify(allPowerTypeFilter)),
      JSON.parse(JSON.stringify(allDepotTypesForFilter)),
      JSON.parse(JSON.stringify(allOfflineDurationFilter)),
      getCountryCodes
    );
    setActiveFilters([]);
  };

  useEffect(() => {
    const activeFilters = [];
    if (checkSingleFilterSelected(allDepotType)) {
      activeFilters.push({
        name: "Depot Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            JSON.parse(JSON.stringify(allDepotTypesForFilter)),
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkDepotSelected(allAccounts)) {
      activeFilters.push({
        name: "Account and Depot",
        onClick: (index) => {
          applyFilter(
            resetAccountsFilter(),
            allMakes,
            allPowerType,
            allDepotType,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkChargersSelected(allMakes)) {
      activeFilters.push({
        name: "Charger Make and Model",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            resetMakeModel(),
            allPowerType,
            allDepotType,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allPowerType)) {
      activeFilters.push({
        name: "Power Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            JSON.parse(JSON.stringify(allPowerTypeFilter)),
            allDepotType,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allOpenDuration)) {
      activeFilters.push({
        name: "Open Duration",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            JSON.parse(JSON.stringify(allOfflineDurationFilter)),
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkNestedFilterSelected(countryCodes)) {
      activeFilters.push({
        name: "Country",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            allOpenDuration,
            getCountryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    setActiveFilters(activeFilters);
  }, [
    allAccounts,
    allMakes,
    allPowerType,
    allDepotType,
    allOpenDuration,
    countryCodes,
  ]);

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllDepotType,
    tempAllOpenDuration,
    tempCountryCode
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllDepotType,
      tempAllOpenDuration,
      tempCountryCode
    );
    getConnectivityOfflineWithDepots(filterObj);
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllDepotType,
    tempAllOpenDuration,
    tempCountryCode
  ) => {
    let selectedAccFilters = [];
    let selectedModelFilters = [];
    let selectedPowerType = [];
    let selectedMakeFilter = [];
    let selectedTimeFilters = [];
    let selectedCountryCode = [];
    let selectedDepotType = [];
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    setAllAcconts([...tempAllAccounts]);

    if (tempAllMakeModel[0].children.length) {
      selectedModelFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    if (tempAllMakeModel[0].children.length) {
      if (tempAllMakeModel[0].checked) selectedMakeFilter = [];
      else {
        tempAllMakeModel[0].children.map((single) => {
          if (single.chargers) {
            let trueCount = single.chargers.filter(
              ({ checked }) => checked === true
            );
            if (trueCount.length > 0) {
              selectedMakeFilter.push(single.name);
            }
          }
        });
      }
    }
    setAllMakes([...tempAllMakeModel]);
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllPowerType([...tempAllPowerType]);
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllDepotType([...tempAllDepotType]);

    if (tempCountryCode[0].children.length) {
      selectedCountryCode = tempCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    setCountryCodes([...tempCountryCode]);
    if (tempAllOpenDuration[0].children.length) {
      selectedTimeFilters = tempAllOpenDuration[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setAllOpenDuration([...tempAllOpenDuration]);
    return {
      depotId: [...selectedAccFilters],
      soldAsIdList: [...selectedModelFilters],
      powerType: [...selectedPowerType],
      time: [...selectedTimeFilters],
      countryCode: [...selectedCountryCode],
      depotType: [...selectedDepotType],
    };
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  return (
    <>
      <OfflinePortsContext.Provider
        value={{
          depotIdParam,
          timeSlot,
          getConnectivityOfflineWithDepots,
        }}
      >
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="Offline Chargers"
        />
        {tableLoaded && correctData ? (
          <PageMainContent>
            <OfflinePortsTable
              toggleDrawer={toggleDrawer}
              offLinePorts={offLinePorts}
              setOffLinePorts={setOffLinePorts}
              selectedFilter={selectedFilter}
              isFiltered={
                allMakes[0]?.checked ||
                Object.values(selectedFilter).filter((e) => e.length).length !==
                  0
              }
              activeFilters={activeFilters}
              clearAllFilters={clearAllFilters}
            />

            <NewCommonFilterDrawer
              DrawerOC={DrawerOC}
              toggleDrawer={toggleDrawer}
            >
              <ConnectivityFilter
                allAccounts={allAccounts}
                allMakes={allMakes}
                allPowerType={allPowerType}
                allOpenDuration={allOpenDuration}
                allDepotType={allDepotType}
                applyFilter={applyFilter}
                countryCode={countryCodes}
              />
            </NewCommonFilterDrawer>
          </PageMainContent>
        ) : (
          <Loader isLoading={true} />
        )}
      </OfflinePortsContext.Provider>
    </>
  );
};

export default ConnectivityOfflinePorts;
