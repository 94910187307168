import React from "react";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import { Tab, Chip, Badge } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import VehicleFilter from "./vehicleFilter";
import TuneIcon from "@mui/icons-material/Tune";
import Controls from "../../components/controls/Controls";
import ToastMessage from "../../components/ToastMessage";
import {
  allDepotTypesForFilter,
  allHomeChargingFilter,
  allPowerTypeFilter,
} from "../../constants/FilterConstants";
import {
  checkSingleFilterSelected,
  checkDepotSelected,
  checkNestedFilterSelected,
  checkChargersSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";
import ActiveFilters from "../../components/all-filter-types/ActiveFilters";
import "./vehicleDiscovery.scss";

import Insights from "./insights";
import AllSessions from "./all-sessions";
import CheckVINVIDMapping from "./check-vin-vid";
import DateRangePicker from "./datepicker";
import {
  VehiclesDiscoveryProvider,
  useVehiclesDiscovery,
} from "./VehiclesDiscoveryContext";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import { getCountryCodesByRegion } from "../../components/common-functions/CommonFunctions";
import { getData } from "../../utils/Storage";
import { variables } from "../../constants/Variables";

const VehicleDiscoveryPage = () => {
  const allTabs = ["Insights", "All Sessions", "Check VIN-VID"];
  const [activeFilters, setActiveFilters] = React.useState([]);

  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState("Insights");
  const {
    fromDate,
    toDate,
    DrawerOC,
    vehicleDiscoveryData,
    toggleDrawer,
    applyFilter,
    allAccounts,
    allMakes,
    allPowerType,
    allDepotType,
    countryCodes,
    toast,
    setToast,
    isFiltered,
    excludeDepotsWithNoVehicles,
    allHomeChargingFlag,
    excludeNoEnergyDeliveredSessions,
    excludeShortSessions,
  } = useVehiclesDiscovery();

  const [selectedAccFilters, setSelectedAccFilters] = React.useState([]);
  const [selectedModelFilters, setSelectedModelFilters] = React.useState([]);
  const [selectedPowerType, setSelectedPowerType] = React.useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState([]);
  const [selectedDepotType, setSelectedDepotType] = React.useState([]);
  const [selectedHomeChargingFlag, setSelectedHomeChargingFlag] =
    React.useState([]);
  const getCountryCodes = getCountryCodesByRegion(
    JSON.parse(getData(variables.USER_REGION))
  );

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const filter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllCountryCode,
      tempAllDepotType,
      tempFromDate,
      tempToDate,
      tempExcludeDepotsWithNoVehicles,
      tempAllHomeChargingFlag,
      tempExcludeNoEnergyDeliveredSessions,
      tempExcludeShortSessions
    );
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    let payload = {
      fromDate: format(new Date(tempFromDate), "yyyy-MM-dd"),
      toDate: format(new Date(tempToDate), "yyyy-MM-dd"),
    };
    if (tempAllAccounts[0].children.length) {
      const selectedAccs = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
      setSelectedAccFilters(selectedAccs);
    }
    if (tempAllMakeModel[0].children.length) {
      const selectedModels = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
      setSelectedModelFilters(selectedModels);
    }
    if (tempAllPowerType[0].children.length) {
      const selectedPower = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
      setSelectedPowerType(selectedPower);
    }
    if (tempAllDepotType[0].children.length) {
      const selectedDepot = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
      setSelectedDepotType(selectedDepot);
    }
    if (tempAllCountryCode[0].children.length) {
      const selectedCountry = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
      setSelectedCountryCode(selectedCountry);
    }
    if (tempAllHomeChargingFlag[0].children.length) {
      const selectedHomeCharging = tempAllHomeChargingFlag[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.name);
          }
          return acc;
        },
        []
      );
      setSelectedHomeChargingFlag(selectedHomeCharging);
    }

    if (selectedCountryCode.length)
      payload = { ...payload, countryCode: [...selectedCountryCode] };
    if (selectedAccFilters.length)
      payload = { ...payload, depotId: [...selectedAccFilters] };
    if (selectedModelFilters.length)
      payload = { ...payload, soldAsIdList: [...selectedModelFilters] };
    if (selectedPowerType.length)
      payload = { ...payload, powerType: [...selectedPowerType] };
    if (selectedDepotType.length)
      payload = { ...payload, depotType: [...selectedDepotType] };
    if (tempExcludeDepotsWithNoVehicles)
      payload.excludeDepotsWithNoVehicles = tempExcludeDepotsWithNoVehicles;
    if (selectedHomeChargingFlag.length === 1)
      payload = {
        ...payload,
        isHomeChargingDepot: selectedHomeChargingFlag[0] === "True",
      };
    if (tempExcludeNoEnergyDeliveredSessions)
      payload.excludeNoEnergyDeliveredSessions =
        tempExcludeNoEnergyDeliveredSessions;
    if (tempExcludeShortSessions)
      payload.excludeShortSessions = tempExcludeShortSessions;

    let temp = payload;
    const res = Object.keys(temp)
      .map(
        (key) =>
          `${key}=${Array.isArray(temp[key]) ? temp[key].join(",") : temp[key]}`
      )
      .join("&");
    history.replace(`/vehicles?${res}`);
    return payload;
  };

  const resetAccountsFilter = () => {
    const allAcc = [...allAccounts];
    allAcc[0].checked = false;
    allAcc[0].children.map((child) => {
      child.checked = false;
      if (child.depotsList) {
        child.depotsList.map((grandChild) => {
          grandChild.checked = false;
        });
      }
    });
    return allAcc;
  };

  const resetMakeModel = () => {
    const makeModel = [...allMakes];
    makeModel[0].checked = false;
    makeModel[0].children.map((child) => {
      child.checked = false;
      if (child.chargers) {
        child.chargers.map((grandChild) => {
          grandChild.checked = false;
          if (grandChild.soldAs) {
            grandChild.soldAs.map((grandGrandChild) => {
              grandGrandChild.checked = false;
            });
          }
        });
      }
    });
    return makeModel;
  };

  const clearAllFilters = () => {
    applyFilter(
      resetAccountsFilter(),
      resetMakeModel(),
      allPowerTypeFilter,
      getCountryCodes,
      allDepotTypesForFilter,
      fromDate,
      toDate,
      false, // excludeDepotsWithNoVehicles
      allHomeChargingFilter,
      false, // excludeNoEnergyDeliveredSessions
      false // excludeShortSessions
    );
    setActiveFilters([]);
  };

  React.useEffect(() => {
    const activeFilters = [];
    if (checkSingleFilterSelected(allDepotType)) {
      activeFilters.push({
        name: "Depot Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            countryCodes,
            JSON.parse(JSON.stringify(allDepotTypesForFilter)),
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkDepotSelected(allAccounts)) {
      activeFilters.push({
        name: "Account and Depot",
        onClick: (index) => {
          applyFilter(
            resetAccountsFilter(),
            allMakes,
            allPowerType,
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkNestedFilterSelected(countryCodes)) {
      activeFilters.push({
        name: "Country",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            getCountryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkChargersSelected(allMakes)) {
      activeFilters.push({
        name: "Model",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            resetMakeModel(),
            allPowerType,
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allPowerType)) {
      activeFilters.push({
        name: "Power Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            JSON.parse(JSON.stringify(allPowerTypeFilter)),
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (excludeDepotsWithNoVehicles) {
      activeFilters.push({
        name: "Depot with Vehicles",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            false,
            allHomeChargingFlag,
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (excludeNoEnergyDeliveredSessions || excludeShortSessions) {
      activeFilters.push({
        name: "Sessions",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            allHomeChargingFlag,
            false,
            false
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allHomeChargingFlag)) {
      activeFilters.push({
        name: "Config Flags",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            countryCodes,
            allDepotType,
            fromDate,
            toDate,
            excludeDepotsWithNoVehicles,
            JSON.parse(JSON.stringify(allHomeChargingFilter)),
            excludeNoEnergyDeliveredSessions,
            excludeShortSessions
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    setActiveFilters(activeFilters);
  }, [
    allAccounts,
    allMakes,
    allPowerType,
    countryCodes,
    allDepotType,
    allHomeChargingFlag,
    selectedDepotType,
    selectedAccFilters,
    selectedModelFilters,
    selectedPowerType,
    selectedCountryCode,
    selectedHomeChargingFlag,
    excludeDepotsWithNoVehicles,
    excludeNoEnergyDeliveredSessions,
    excludeShortSessions,
  ]);

  return (
    <>
      <OpsBreadcrumb title="Vehicle Discovery" />
      <div className="wrap">
        <PageHeader title={"Vehicle Discovery"} />
        <div className="lwrap">
          <ActiveFilters filters={activeFilters} clearAll={clearAllFilters} />
          <Badge
            color="primary"
            variant="dot"
            invisible={isFiltered ? false : true}
          >
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filterIcon" />}
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              id="vehicleDiscoveryFilter"
            />
          </Badge>
        </div>
        <div className="break"></div>
        <div className="rwrap">
          <DateRangePicker />
        </div>
      </div>
      <TabContext value={currentTab}>
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          sx={{
            borderBottom: "1px solid #d6d7de",
            "& .MuiTabs-indicator": {
              backgroundColor: "#1890ff",
            },
          }}
        >
          {allTabs?.map((single) => {
            if (single === "All Sessions") {
              return [
                <Tab key={single} label={single} value={single} />,
                <Chip
                  label={vehicleDiscoveryData?.length || 0}
                  className="chipStyle"
                  size="small"
                />,
              ];
            }
            return <Tab key={single} label={single} value={single} />;
          })}
        </TabList>
        {allTabs?.map((single) => {
          return (
            <TabPanel key={single} value={single}>
              {/** Placeholder Tab */}
              {single === "Insights" && <Insights />}
              {single === "All Sessions" && <AllSessions />}
              {single === "Check VIN-VID" && <CheckVINVIDMapping />}
            </TabPanel>
          );
        })}
      </TabContext>
      <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
        <VehicleFilter
          applyFilter={applyFilter}
          setFilter={filter}
          allAccounts={allAccounts}
          allMakes={allMakes}
          allPowerType={allPowerType}
          allDepotType={allDepotType}
          countryCode={countryCodes}
          fromDate={fromDate}
          toDate={toDate}
          excludeDepotsWithNoVehicles={excludeDepotsWithNoVehicles}
          allHomeChargingFlag={allHomeChargingFlag}
          excludeNoEnergyDeliveredSessions={excludeNoEnergyDeliveredSessions}
          excludeShortSessions={excludeShortSessions}
        />
      </NewCommonFilterDrawer>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

const VehicleDiscovery = () => {
  return (
    <div>
      <VehiclesDiscoveryProvider>
        <VehicleDiscoveryPage />
      </VehiclesDiscoveryProvider>
    </div>
  );
};

VehicleDiscovery.displayName = "VehicleDiscovery";

export default VehicleDiscovery;
