import React, { useEffect, useMemo, useState } from "react";
import {
  Chip,
  Grid,
  InputAdornment,
  Table,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import useTable from "../../components/UseTable";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import PageMainContent from "../../components/PageMainContent";
import { useCallback } from "react";
import Controls from "../../components/controls/Controls";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Popup from "../../components/Popup";
import Configurations from ".";
import ToastMessage from "../../components/ToastMessage";
import "./configurations.scss";

export default function RolesModules() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (item) => item });
  const [refreshRoles, setRefreshRoles] = useState(true);
  const [replacementRole, setReplacementRole] = useState("Read Only");
  const [replacementRoleId, setReplacementRoleId] = useState("readonly");
  const [roleCount, setRoleCount] = useState("");
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { ROLES } = indexedEndPoints;

  const userInfo = useSelector((state) => state.user);
  const { userRole = "" } = userInfo;

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(ROLES);
      setLoading(false);
      setData(response?.data);
      setRefreshRoles(false);
    };
    refreshRoles && fetchRoles();
  }, [refreshRoles]);

  const handleEditRole = (row) => {
    setOpenPopup({
      isOpen: true,
      title: "Update Role",
      child: "editRole",
      item: row,
    });
  };

  const handleDeleteRole = (row) => {
    setRoleCount(row?.count);
    setOpenPopup({
      isOpen: true,
      title: "Are you sure you want to delete this role?",
      child: "deleteRole",
      item: row?.roleId,
    });
  };

  const DeleteRoleDialog = ({ roleId }) => (
    <div>
      <Loader isLoading={loading} />
      {roleCount > 0 && (
        <>
          <p>
            Deleting this role will automatically reassign its {roleCount}&nbsp;
            users to restricted read only permissions.
          </p>
          <p>
            You can select a different replacement role to re-assign these users
            now or you can manually reassign individual users to different roles
            later.
          </p>
          <Controls.Select
            name="roleReplacement"
            label="Replacement role"
            data-testid="roleReplacement"
            options={data
              ?.filter((row) => row.roleId !== roleId)
              ?.map((row) => row.name)}
            value={replacementRole}
            onChange={(e) => {
              setReplacementRole(e.target.value);
              setReplacementRoleId(
                data?.find((row) => row.name === e.target.value)?.roleId
              );
            }}
          />
        </>
      )}
      <div className="deleteRoleModal">
        <Controls.Button
          variant="outlined"
          text="Delete"
          color="primary"
          onClick={() => deleteRole(openPopup?.item)}
        />
        <Controls.Button
          text="Cancel"
          color="primary"
          onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
        />
      </div>
    </div>
  );

  const deleteRole = async (roleId) => {
    setLoading(true);
    let params = `currentRoleId=${roleId}&currentUsersCount=${roleCount}`;
    if (roleCount > 0) params += `&desiredRoleId=${replacementRoleId}`;
    const response = await apiProvider.del(`${ROLES}?${params}`);
    setLoading(false);
    if (response?.statusCode >= 200 && response?.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Role deleted successfully",
        type: "success",
      });
      setOpenPopup({ ...openPopup, isOpen: false });
      setRefreshRoles(true);
    } else {
      setToast({
        isOpen: true,
        message: "Role deletion failed",
        type: "error",
      });
    }
  };

  const headers = useMemo(
    () => [
      { id: "name", label: "Role" },
      {
        id: "region",
        label: "Region",
        render: (row, col) =>
          row[col]?.length > 1 ? (
            <Chip label="Global" />
          ) : (
            row[col] && <Chip label={row[col][0]} />
          ),
        disableSorting: true,
      },
      {
        id: "rolePrivilege",
        label: "Modules",
        render: (row, col) => row[col]?.join(", "),
        styles: { width: "650px" },
      },
      // {
      //   id: "count",
      //   label: "Users",
      //   render: (row, col) => <Chip label={row[col]} />,
      // },
      {
        disableSorting: true,
        id: "actions",
        label: "Action",
        render: (row, col, ind) => (
          <div className="roleTableActions">
            <Controls.ActionButton
              color="primary"
              onClick={() => handleEditRole(row)}
              // disabled={row?.roleId === "admin"}
              data-testid={`editRoleBtn${ind}`}
            >
              <Tooltip arrow TransitionComponent={Zoom} title="Update Role">
                <EditOutlined fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
            <Controls.ActionButton
              color="primary"
              onClick={() => handleDeleteRole(row)}
              // disabled={row?.roleId === "admin"}
              disabled={true}
              data-testid={`deleteRoleBtn${ind}`}
            >
              <Tooltip arrow TransitionComponent={Zoom} title="Delete Role">
                <DeleteIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </div>
        ),
      },
    ],
    [data]
  );

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headers, filterFn);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value === "") return items;
        else
          return items?.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value.toLowerCase())
            )
          );
      },
    });
  };

  const ToolbarMemoised = useCallback(
    ({ handleSearch, setOpenPopup }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Grid sm item />

          {userRole == "admin" && (
            <Controls.Button
              text="Add Role"
              variant="outlined"
              data-testid="addRoleBtn"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Add Role",
                  child: "addRole",
                  item: data,
                })
              }
            />
          )}
        </Toolbar>
      );
    },
    [userRole, data]
  );

  return (
    <>
      <OpsBreadcrumb title="Configurations" />
      <PageHeader title="All Roles" />
      <Loader isLoading={loading} />
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          setOpenPopup={setOpenPopup}
        />
        <TableContainer>
          <Table>
            <TableHead />
            {TableBody}
          </Table>
        </TableContainer>
        {TablePagination}
      </PageMainContent>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        bottom={openPopup?.child === "deleteRole" ? 0 : 10}
        minWidth={openPopup?.child === "deleteRole" ? "40%" : "90%"}
      >
        {openPopup?.child === "deleteRole" ? (
          <DeleteRoleDialog roleId={openPopup?.item} />
        ) : (
          <Configurations
            type={openPopup?.child}
            rolesRawData={openPopup?.item}
            setOpenPopup={setOpenPopup}
            setRefreshRoles={setRefreshRoles}
            setToast={setToast}
          />
        )}
      </Popup>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
}
