import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Grid, InputAdornment, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import TuneIcon from "@mui/icons-material/Tune";
import GetAppIcon from "@mui/icons-material/GetApp";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import useTable from "../../../components/UseTable";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../../redux-state/actions";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import AccountSelection from "../../reports/accountsSidesDrawer";
import ExportCSV from "../../../components/ExportCSV";
import Popup from "../../../components/Popup";
import ActiveFilters from "../../../components/all-filter-types/ActiveFilters";
import { checkDepotSelected } from "../../../components/all-filter-types/isFilteredCheckFunctions";
import CommonDrawer from "../../../components/SideDrawer";
import ToastMessage from "../../../components/ToastMessage";

const allBreadcrumbsLinks = [
  {
    link: routePaths.CHARGERFIRMWAREDETAILS,
    title: "Chargers",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const headCells = [
  { id: "chargerId", label: "Charger ID" },
  { id: "companyName", label: "Company Name" },
  { id: "depotName", label: "Depot Name" },
  { id: "operation", label: "Operation" },
  {
    id: "performedAt",
    label: "Performed At",
    type: "date",
    render: (row, col) => row[col]?.replace("GMT", "UTC"),
  },
  { id: "performedBy", label: "Performed By" },
];

export default function ChargerHistory() {
  const [activeFilters, setActiveFilters] = useState([]);
  const allColumnsHeaders = useMemo(
    () => headCells?.map((row) => ({ key: row.id, label: row.label })),
    []
  );
  const [headers, setHeaders] = useState(
    headCells?.map((row) => ({ key: row.id, name: row.label, checked: false }))
  );
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const classes = useStyles();
  const { GET_CHARGERS_HISTORY } = indexedEndPoints;
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesDataLoading, setCompaniesDataLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [DrawerOC, setDrawerOC] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [DrawerObj, setDrawerObj] = useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const toggleDetailsDrawer = (openClose, title, item) => {
    setDrawerObj({ isOpen: openClose, title: title, data: item });
  };

  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      setCompaniesData(tempAllCompaniesV2);
      tempAllCompaniesV2?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            singleChild["checked"] = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setCompanies(tempAccounts);
      setCompaniesDataLoading(false);
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(GET_CHARGERS_HISTORY);
      setLoading(false);
      const data = response?.data?.map((row) => {
        let matchedCompany = companiesData?.find(
          (item) => item?.companyId === row?.companyId
        );
        let result = matchedCompany
          ? {
              ...row,
              companyName: matchedCompany.companyName,
              depotName: matchedCompany.depotsList?.find(
                (item) => item?.depotId === row?.depotId
              )?.depotName,
            }
          : { ...row };
        return result;
      });
      setData(data);
      setFilteredData(data);
    };
    companiesData?.length && fetchHistory();
  }, [companiesData]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const clearAll = () => {
    companies[0].checked = false;
    companies[0].children.map((child) => {
      child.checked = false;
      if (child.depotsList) {
        child.depotsList.map((grandChild) => {
          grandChild.checked = false;
        });
      }
    });
    setCompanies([...companies]);
  };

  useEffect(() => {
    const activeFilters = [];
    if (checkDepotSelected(companies)) {
      activeFilters.push({
        name: "Account and Depot",
        onClick: (index) => {
          clearAll();
          setFilteredData(data);
          setIsFiltered(false);
          activeFilters.splice(index, 1);
        },
      });
    }
    setActiveFilters(activeFilters);
  }, [companies]);

  const applyFilter = () => {
    toggleDrawer(false, "FILTER", {});
    const selectedAccFilters = companies[0].children.reduce((acc, cur) => {
      const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
        if (curCh.checked) {
          accCh.push(curCh.depotId);
        }
        return accCh;
      }, []);
      acc.push(...selectedChildElements);
      return acc;
    }, []);
    setFilteredData(
      selectedAccFilters?.length
        ? data?.filter((row) => selectedAccFilters?.includes(row.depotId))
        : data
    );
    setIsFiltered(selectedAccFilters?.length > 0);
  };

  const onTableRowClick = (row) => {
    toggleDetailsDrawer(true, "Details", row);
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(
    filteredData,
    headCells,
    filterFn,
    false,
    false,
    null,
    onTableRowClick
  );

  const ToolbarMemoised = useCallback(
    ({ handleSearch }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Tooltip title={"Shows operations performed through Ops Console"}>
            <InfoIcon color="primary" />
          </Tooltip>
          <Grid sm item />
          <Grid>
            <Controls.Button
              text="Download"
              variant="outlined"
              disabled={filteredData?.length === 0}
              startIcon={<GetAppIcon className="chdDownloadIcon" />}
              onClick={() =>
                setOpenPopup({
                  isOpen: true,
                  title: "Export CSV",
                  child: "exportCSV",
                })
              }
            />
          </Grid>
          <ActiveFilters
            filters={activeFilters}
            clearAll={() => {
              clearAll();
              setFilteredData(data);
              setIsFiltered(false);
              setActiveFilters([]);
            }}
          />
          <Badge color="primary" variant="dot" invisible={!isFiltered}>
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filter_icon" />}
              onClick={() =>
                setDrawerOC({
                  isOpen: true,
                  title: "FILTER",
                  data: {},
                  type: "filterData",
                })
              }
              className="filter"
              style={{
                border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
              }}
            />
          </Badge>
        </Toolbar>
      );
    },
    [filteredData, isFiltered]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Charger History"
      />
      <PageHeader title="Charger History" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading || companiesDataLoading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          <AccountSelection
            clearAll={clearAll}
            applyFilter={applyFilter}
            allAccounts={companies}
            setAllAcconts={() => setCompanies([...companies])}
          />
        </NewCommonFilterDrawer>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={filteredData}
            allColumnsHeaders={allColumnsHeaders}
            headers={headers}
            setHeaders={setHeaders}
            fileName={"Chargers History.csv"}
          />
        </Popup>
        <CommonDrawer DrawerOC={DrawerObj} toggleDrawer={toggleDetailsDrawer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Company ID</p>
                <Controls.CopyToClipboard
                  name={DrawerObj?.data?.companyId}
                  setToast={setToast}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Depot ID</p>
                <Controls.CopyToClipboard
                  name={DrawerObj?.data?.depotId}
                  setToast={setToast}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="single_details">
                <p className="title">Request payload</p>
                <Controls.CopyToClipboard
                  name={DrawerObj?.data?.payload}
                  isJson={true}
                  setToast={setToast}
                />
              </div>
            </Grid>
          </Grid>
        </CommonDrawer>
        <ToastMessage toast={toast} setToast={setToast} />
      </PageMainContent>
    </>
  );
}
