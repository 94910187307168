import React, { useEffect } from "react";
import { getOCPPSettingsByCharger } from "../chargerFirmwareService";
import Controls from "../../../../components/controls/Controls";
import { CircularProgress } from "@mui/material";

const ChargerLastKnownOCPP = (props) => {
  const [data, setdata] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const getChargerOcppSettings = async () => {
      if (props?.DrawerOC?.data?.chargerId && props?.DrawerOC?.data?.depotId) {
        setLoading(true);
        const res = await getOCPPSettingsByCharger(
          props?.DrawerOC?.data?.depotId,
          props?.DrawerOC?.data?.chargerId
        );
        if (res.statusCode >= 200 && res.statusCode <= 299) {
          setdata(res.data);
          setLoading(false);
        } else {
          setdata([]);
          setLoading(false);
        }
      }
    };
    getChargerOcppSettings();
  }, []);

  return (
    <>
      <div>
        <div className="single_details">
          <p className="title">Charger Name</p>
          <p className="title_value">{props?.DrawerOC?.data?.chargerName}</p>
        </div>
        {loading ? (
          <div className="loading_main">
            <div className="loading_cllm">
              <CircularProgress size={30} />
            </div>
          </div>
        ) : (
          <Controls.CopyToClipboard
            name={data}
            isJson={true}
            setToast={props.setToast}
            max_Height={"600px"}
          />
        )}
      </div>
    </>
  );
};
export default ChargerLastKnownOCPP;
