import React, { useEffect, useState } from "react";
import Controls from "../../../../components/controls/Controls";
import { chargerProfileTemplates } from "./chargerProfileTemplates";
import { DialogContent, Grid } from "@mui/material";

export default function AddEditChargeProfile({
  chargerName,
  depotId,
  setOpenPopup,
  handleAddChargerProfile,
}) {
  const scheduleZonesList = Object.values(chargerProfileTemplates)?.map(
    (item) => item.scheduleZone
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    chargerProfileTemplates["ukSmartChargeRegulation"]
  );
  const [selectedScheduleZone, setSelectedScheduleZone] = useState(
    chargerProfileTemplates["ukSmartChargeRegulation"].scheduleZone
  );

  useEffect(() => {
    let templateName = "";
    for (let [key, value] of Object.entries(chargerProfileTemplates)) {
      if (value.scheduleZone === selectedScheduleZone) {
        templateName = key;
        break;
      }
    }
    setSelectedTemplate(chargerProfileTemplates[templateName]);
  }, [selectedScheduleZone]);

  const handleSubmit = () => {
    const payload = {
      name: chargerName + selectedTemplate["profileName"],
      controls: [
        {
          chargeMode: {
            chargingMode: selectedTemplate["chargingMode"],
            target: {
              soc: selectedTemplate["soc"],
              kWh: selectedTemplate["kwh"],
            },
            enableVehiclePreconditioning: selectedTemplate["preconditioning"],
          },
          accessControlSchedules: selectedTemplate["accessControlSchedules"],
        },
      ],
      siteId: depotId,
    };
    if (selectedTemplate["chargingMode"] !== "unmanaged") {
      payload.controls[0].chargeMode = {
        ...payload.controls[0].chargeMode,
        isVACEnabled: false,
        blackoutType: selectedTemplate["blackoutType"],
        dwellTime: selectedTemplate["dwellTime"],
        target: {
          soc: selectedTemplate["soc"],
          kWh: selectedTemplate["kwh"],
          identified: {
            soc: null,
            kWh: null,
          },
          unIdentified: {
            soc: null,
            kWh: null,
          },
        },
      };
    }
    handleAddChargerProfile(payload);
  };

  return (
    <div>
      <p className="chargerProfileScheduleLabel value">
        This action will create a charge management profile with the settings
        below and assign this charger to the new profile
      </p>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controls.Select
            className="width100"
            label="Select Schedule Zone *"
            options={scheduleZonesList}
            value={selectedScheduleZone}
            onChange={(e) => setSelectedScheduleZone(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            className="width100"
            disabled
            label="Charge profile title *"
            value={chargerName + selectedTemplate["profileName"]}
          />
        </Grid>
      </Grid>
      {selectedTemplate["scheduleLabel"] ? (
        <div>
          <DialogContent dividers />
          <p className="chargerProfileScheduleLabel">
            {selectedTemplate["scheduleLabel"]}
          </p>
          <div>
            <div className="chargerProfileSchedules">
              <p className="label">Restricts charging the hours</p>
              <p className="value">{selectedTemplate["chargingSchedule"]}</p>
            </div>
            <div className="flex_div_space_between">
              <div className="chargerProfileSchedules">
                <p className="label">Preconditioning</p>
                <p className="value">
                  {selectedTemplate["preconditioning"] ? "Enabled" : "Disabled"}
                </p>
              </div>
              <div className="chargerProfileSchedules">
                <p className="label">Dwell Time</p>
                <p className="value">
                  {selectedTemplate["dwellTime"] + " minutes"}
                </p>
              </div>
              <div className="chargerProfileSchedules">
                <p className="label">SOC %</p>
                <p className="value">{selectedTemplate["soc"] + "%"}</p>
              </div>
              <div className="chargerProfileSchedules">
                <p className="label">kWh Limit</p>
                <p className="value">{selectedTemplate["kwh"] || "off"}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="deleteRoleModal">
        <Controls.Button
          variant="outlined"
          text="Cancel"
          color="primary"
          onClick={() => setOpenPopup((op) => ({ ...op, isOpen: false }))}
        />
        <Controls.Button text="Save" color="primary" onClick={handleSubmit} />
      </div>
    </div>
  );
}
