import environment from "../../environment";
import {
  apiProvider,
  indexedEndPoints,
} from "../../services/api/utilities/provider";

const { GET_REPORTS_HISTORY, POST_REPORTS_GENERATE } = indexedEndPoints;

export const getReportsHistory = async (queryparam, payload) =>
  payload
    ? await apiProvider.post(`${GET_REPORTS_HISTORY}?${queryparam}`, payload)
    : await apiProvider.post(`${GET_REPORTS_HISTORY}?${queryparam}`, {});

export const reportGenerate = async (payload) =>
  await apiProvider.post(POST_REPORTS_GENERATE, payload);
