export const getCheckedValues = (arr, key) => {
  return arr?.filter((item) => item[key] === true).map((item) => item[key]);
};

export const cryptoRandom = () => {
  const typedArray = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0];
  const randomFloat = randomValue / Math.pow(2, 8);
  return randomFloat;
};

export const getTimezoneInHourMin = (ts) => {
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
  const tzOffset = -ts.getTimezoneOffset();
  const diff = tzOffset >= 0 ? "+" : "-";
  const timezone = diff + pad(tzOffset / 60) + ":" + pad(tzOffset % 60);
  return timezone; // returns timezone in (+/-)HH:mm
};

export const getCountryCodesByRegion = (region) => {
  let countries = [];
  if (region?.includes("NA")) {
    countries.push({
      name: "NA",
      checked: false,
      isExpanded: true,
      children: [
        {
          checked: false,
          name: "United states",
          value: "USA",
        },
        {
          checked: false,
          name: "Canada",
          value: "CAN",
        },
        {
          checked: false,
          name: "Mexico",
          value: "MEX",
        },
      ],
    });
  }
  if (region?.includes("EU")) {
    countries.push({
      name: "EU",
      checked: false,
      isExpanded: true,
      children: [
        {
          checked: false,
          name: "United Kingdom",
          value: "GBR",
        },
        {
          checked: false,
          name: "Germany",
          value: "DEU",
        },
        {
          checked: false,
          name: "Spain",
          value: "ESP",
        },
        {
          checked: false,
          name: "France",
          value: "FRA",
        },
        {
          checked: false,
          name: "Netherland",
          value: "NLD",
        },
        {
          checked: false,
          name: "Ireland",
          value: "IRL",
        },
        {
          checked: false,
          name: "Denmark",
          value: "DNK",
        },
        {
          checked: false,
          name: "Italy",
          value: "ITA",
        },
        {
          checked: false,
          name: "Belgium",
          value: "BEL",
        },
      ],
    });
  }
  return [
    {
      checked: false,
      isExpanded: true,
      name: "country",
      children: countries,
    },
  ];
};
