import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/all-filter-types/FilterTabPanel";
import SingleTypeFilter from "../../components/all-filter-types/SingleFilter";
import Controls from "../../components/controls/Controls";
import {
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../components/all-filter-types/isFilteredCheckFunctions";
import NestedFilterWithSearch from "../../components/all-filter-types/NestedFilter";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function UserAlertSettingsFilter({
  depotTypes,
  setDepotTypes,
  custAcc,
  setCustAcc,
  setIsFiltered,
  toggleDrawer,
  handleSubmit,
}) {
  const [value, setValue] = useState("Customer");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="filters_with_vertical_tab">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            label="Customer"
            value={"Customer"}
            icon={
              checkSingleFilterSelected(custAcc) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
          <Tab
            label="Depot Type"
            value={"Depot Type"}
            icon={
              checkSingleFilterSelected(depotTypes) ? (
                <FiberManualRecordIcon className="filter_dot" />
              ) : null
            }
            iconPosition="end"
          />
        </Tabs>
        <TabPanel value={value} index={"Customer"}>
          <div className="filter_tab_vertical_body">
            <SingleTypeFilter
              _stateVariable={custAcc}
              setFuntion={setCustAcc}
              isSearchable={true}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={"Depot Type"}>
          <div className="filter_tab_vertical_body">
            <SingleTypeFilter
              _stateVariable={depotTypes}
              setFuntion={setDepotTypes}
            />
          </div>
        </TabPanel>
      </Box>
      <div className="filter_action_btn">
        <Controls.Button
          disabled={
            !(
              checkSingleFilterSelected(custAcc) ||
              checkSingleFilterSelected(depotTypes)
            )
          }
          text="Clear All"
          variant="outlined"
          className="userAlertFilter"
          onClick={() => {
            setDepotTypes([
              {
                ...depotTypes[0],
                checked: false,
                children: depotTypes[0]?.children?.map((item) => ({
                  ...item,
                  checked: false,
                })),
              },
            ]);
            setCustAcc([
              {
                ...custAcc[0],
                checked: false,
                children: custAcc[0]?.children?.map((item) => ({
                  ...item,
                  checked: false,
                })),
              },
            ]);
          }}
        />
        <Controls.Button
          text="Apply"
          color="primary"
          className="userAlertFilter"
          onClick={() => {
            setIsFiltered(
              depotTypes[0]?.children?.some((item) => item.checked) ||
                custAcc[0]?.children?.some((item) => item.checked)
            );
            toggleDrawer(false, "Customer Accounts", {});
            handleSubmit();
          }}
        />
      </div>
    </div>
  );
}

export const VendorErrorCodeFilter = ({
  allErrorCodes,
  setAllErrorCodes,
  selectedErrorCodes,
  applyFilter,
  clearAll,
}) => {
  const [value, setValue] = React.useState("Vendor Error Code");

  return (
    <>
      <div className="filters_with_vertical_tab">
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <TabPanel value={value} index={"Vendor Error Code"}>
            <div className="filter_tab_vertical_body">
              <NestedFilterWithSearch
                _allData={allErrorCodes}
                setFuntion={setAllErrorCodes}
                searchPlaceholderText={"Search Error Code or Vendor Error Code"}
              />
            </div>
          </TabPanel>
        </Box>
        <div className="filter_action_btn">
          <Controls.Button
            disabled={!selectedErrorCodes?.length}
            text="Clear All"
            variant="outlined"
            onClick={() => clearAll()}
            className="btn_font_size"
          />
          <Controls.Button
            text="Apply"
            color="primary"
            onClick={() => applyFilter()}
            className="btn_font_size"
          />
        </div>
      </div>
    </>
  );
};
